import React from 'react';

const loading = () => {
	return (
		<>
			<label>Carregando...</label>
		</>
	);
};

export default loading;
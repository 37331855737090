import React, { useState, useEffect } from "react";
import "./style.css";
import { useHistory } from "react-router-dom";

const ProgressBar = ({
	resetTimeOperation,
	setResetTimeOperation
}) => {
  const history = useHistory();
  const [value, setValue] = useState(100);

  useEffect(() => {
    if (value === 0) {
      history.push("../panel-toten");
    }
  }, [value])

  useEffect(() => {
    if (resetTimeOperation === true) {
      setValue(100);
      setResetTimeOperation(false);
    }
  }, [resetTimeOperation])

  useEffect(() => {
    const interval = setInterval(() => {
      setValue(prevValue => prevValue - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="progress-div">
      <div style={{ width: `${value}%` }} className="bar-time-operation"></div>
    </div>
  );
};

export default ProgressBar;

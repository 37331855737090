import React from 'react';
import { Link } from "react-router-dom";
import ImageCart from "../../../assets/images/cart.png";
import { HiChevronLeft } from "react-icons/hi";
import './style.css';

const HeaderShoppingCart = () => {
	return (
		<div className="area-header-shopping-cart">
			<div className="top-header">
				<Link to="../panel-toten"
                ><HiChevronLeft size={90} /> VOLTAR</Link>
			</div>

			<div className="bottom-header">
				<img src={ImageCart} />
				<h1>CARRINHO</h1>
			</div>
		</div>
	);
};

export default HeaderShoppingCart;
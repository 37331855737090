import React, {useState, useEffect} from 'react';
import HeaderToten from '../Header/index.jsx';
import { Button, Form, Row, Col } from 'react-bootstrap';
import apiService from '../../../../services/api.js';
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import Loading from '../../../../components/Loading/index.jsx';
import { Can } from "react-access-level";
import { showNotify, verifyError } from '../../../../services/actionsAppService.jsx';

const RegisterToten = () => {
	const api = apiService.get()
	const history = useHistory();
	const [stores, setStores] = useState([]);
	const [errors, setErrors] = useState('');
	const [loading, setLoading] = useState(false);

	const { register, handleSubmit } = useForm();

	const onSubmit = data => {
		setLoading(true);
		
		api.post('api/toten', data)
		.then(resp => {
			showNotify('success', 'Toten registrado com sucesso!');
			setLoading(false);
			console.log(resp.data);
			history.push('/dashboard/toten/list');
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			setErrors(error?.response?.data?.data?.errors)
			setLoading(false);
			verifyError(error);
		})
	}

	const initStores = () => {
		api.get('api/store/all')
		.then(resp => {
			const data = resp.data.data.stores;
			setStores(data)
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			setErrors(error.message)
			verifyError(error);
		})
	}

	useEffect(() => {
		initStores();
		
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<div className="area-crud">
				<HeaderToten></HeaderToten>

				<Can 
					resource="toten"
					authority="toten-create"
					otherwiseComponent={<label>Sem acesso!</label>}
				>
					<h5 className="mb-3">Formulário de registro</h5>

					{ (loading) ? (<Loading />) : (null)}

					<Form onSubmit={handleSubmit(onSubmit)} className="form-register">
						<Row className="mb-3">
							<Col sm="6">
								<Form.Group>
								    <label htmlFor="name" className="form-label">Nome:</label>
								    <input type="text" name="name" className="form-control" id="name" {...register("name")} />
								    <span className="error-message">{errors?.name ? errors.name[0] : null}</span>
								</Form.Group>
							</Col>

							<Col sm="6">
								<Form.Group>
								    <label htmlFor="status" className="form-label">Status:</label>
								    <select className="form-select" name="status" {...register("status")}>
								    	<option value="funcionando">Funcionando</option>
								    	<option value="manutenção">Manutenção</option>
								    	<option value="testando">Testando</option>
							        </select>
								    <span className="error-message">{errors?.status ? errors.status[0] : null}</span>
								</Form.Group>
							</Col>
						</Row>

						<Row className="mb-3">
							<Col sm="6">
								<Form.Group>
								    <label htmlFor="store_id" className="form-label">Loja:</label>
								    <select className="form-select" name="store_id" {...register("store_id")}>
								    	<option value="">Selecionar</option>
							            {stores?.map((store, index) => (
								    		<option value={store.id} key={index}>{store.name}</option>
								    	))}
							        </select>
								    <span className="error-message">{errors?.store_id ? errors.store_id[0] : null}</span>
								</Form.Group>
							</Col>

							<Col sm="6">
								<Form.Group>
								    <label htmlFor="phone_number" className="form-label">Telefone:</label>
								    <input type="text" name="phone_number" className="form-control" id="phone_number" {...register("phone_number")} />
								    <span className="error-message">{errors?.phone_number ? errors.phone_number[0] : null}</span>
								</Form.Group>
							</Col>
					    </Row>

						<Row className="mb-3">
							<Col sm="6">
								<Form.Group>
								    <label htmlFor="email" className="form-label">Email:</label>
								    <input type="text" name="email" className="form-control" id="email" {...register("email")} />
								    <span className="error-message">{errors?.email ? errors.email[0] : null}</span>
								</Form.Group>
							</Col>

							<Col sm="6">
								<Form.Group>
								    <label htmlFor="password" className="form-label">Senha:</label>
								    <input type="password" name="password" className="form-control" id="password" {...register("password")} />
								    <span className="error-message">{errors?.password ? errors.password[0] : null}</span>
								</Form.Group>
							</Col>

							

							{/*<Col sm="4">
								<Form.Group>
								    <label htmlFor="client_id" className="form-label">Id do cliente:</label>
								    <input type="text" name="client_id" className="form-control" id="client_id" {...register("client_id")} />
								    <span className="error-message">{errors?.client_id ? errors.client_id[0] : null}</span>
								</Form.Group>
							</Col>*/}
						</Row>

						{/*<Row className="mb-3">
							<Col sm="12">
								<Form.Group>
								    <label htmlFor="token" className="form-label">Token:</label>
								    <textarea type="text" name="token" rows="8" className="form-control" id="token" {...register("token")}></textarea>
								    <span className="error-message">{errors?.token ? errors.token[0] : null}</span>
								</Form.Group>
							</Col>
						</Row>*/}

					    <Row className="mb-3">
					    	<Col>
					    		<Button type="submit" className="btn">Cadastrar</Button>
					    	</Col>
					    </Row>
					</Form>
				</Can>
			</div>
		</>
	);
};

export default RegisterToten;
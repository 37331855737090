import React, { useEffect } from "react";
import "./style.css";
import logo2 from "../../../../assets/images/logo-2.png";
import Footer from "../../../../components/PanelToten/Footer/index.jsx";

const FinishPayment = () => {
  const locationInitApp = () => {
    setTimeout(function () {
      window.location = "/panel-toten";
    }, 20000);
  };

  useEffect(() => {
    locationInitApp();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="wrapper-landscape">
      <div className="page-finish-payment-landscape">
        <p className="msg-purchase-successfully">
          Obrigado, pedido realizado com sucesso :)
        </p>
        <div className="image-info-wrapper">
          <label className="msg-obs">Baixe agora o app</label>
          <img className="logo" src={logo2} />
          <label className="msg-obs">app.zaittech.info</label>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default FinishPayment;

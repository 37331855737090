import React from 'react';

import './style.css';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import PrivateRoute from "../../../components/Route/privateRoute.jsx";
import Header from '../Header/index.jsx';
import Sidebar from '../Sidebar/index.jsx';

import Overview from '../../../pages/Dashboard/Overview/View/index.jsx';

import ListStore from '../../../pages/Dashboard/Store/List/index.jsx';
import RegisterStore from '../../../pages/Dashboard/Store/Register/index.jsx';
import EditStore from '../../../pages/Dashboard/Store/Edit/index.jsx';
import ViewStore from '../../../pages/Dashboard/Store/View/index.jsx';

import ListUser from '../../../pages/Dashboard/User/List/index.jsx';
import RegisterUser from '../../../pages/Dashboard/User/Register/index.jsx';
import EditUser from '../../../pages/Dashboard/User/Edit/index.jsx';
import ViewUser from '../../../pages/Dashboard/User/View/index.jsx';

import ListToten from '../../../pages/Dashboard/Toten/List/index.jsx';
import RegisterToten from '../../../pages/Dashboard/Toten/Register/index.jsx';
import EditToten from '../../../pages/Dashboard/Toten/Edit/index.jsx';
import ViewToten from '../../../pages/Dashboard/Toten/View/index.jsx';

import ListRole from '../../../pages/Dashboard/AccessControl/List/index.jsx';
import RegisterRole from '../../../pages/Dashboard/AccessControl/Register/index.jsx';
import EditRole from '../../../pages/Dashboard/AccessControl/Edit/index.jsx';
import ViewRole from '../../../pages/Dashboard/AccessControl/View/index.jsx';

import ListPurchase from '../../../pages/Dashboard/Purchase/List/index.jsx';
import ViewPurchase from '../../../pages/Dashboard/Purchase/View/index.jsx';
import ListLogsWebhook from '../../../pages/Dashboard/Purchase/ListLogsWebhook/index.jsx';

import ViewProfile from '../../../pages/Dashboard/Profile/View/index.jsx';

import ErrorPage from '../../../pages/Error/index.jsx';

const Main = () => {
	return (
		<>
		    <div className="area-dashboard">
		    	<BrowserRouter>
		    		<Sidebar></Sidebar>

		    		<div className="area-main">
		    			<Header></Header>
			    		<Switch>
			    			<PrivateRoute path="/dashboard" exact component={Overview} />

			    			<PrivateRoute path="/dashboard/store/list" component={ListStore} />
			    			<PrivateRoute path="/dashboard/store/register" component={RegisterStore} />
			    			<PrivateRoute path="/dashboard/store/edit/:id" component={EditStore} />
			    			<PrivateRoute path="/dashboard/store/view/:id" component={ViewStore} />
			    			<PrivateRoute path="/dashboard/store/search/:column/:value" component={ListStore} />

			    			<PrivateRoute path="/dashboard/user/list" component={ListUser} />
			    			<PrivateRoute path="/dashboard/user/register" component={RegisterUser} />
			    			<PrivateRoute path="/dashboard/user/edit/:id" component={EditUser} />
			    			<PrivateRoute path="/dashboard/user/view/:id" component={ViewUser} />
			    			<PrivateRoute path="/dashboard/user/search/:column/:value" component={ListUser} />

			    			<PrivateRoute path="/dashboard/toten/list" component={ListToten} />
			    			<PrivateRoute path="/dashboard/toten/register" component={RegisterToten} />
			    			<PrivateRoute path="/dashboard/toten/edit/:id" component={EditToten} />
			    			<PrivateRoute path="/dashboard/toten/view/:id" component={ViewToten} />
			    			<PrivateRoute path="/dashboard/toten/search/:column/:value" component={ListToten} />

			    			<PrivateRoute path="/dashboard/access-control/list" component={ListRole} />
			    			<PrivateRoute path="/dashboard/access-control/register" component={RegisterRole} />
			    			<PrivateRoute path="/dashboard/access-control/edit/:id" component={EditRole} />
			    			<PrivateRoute path="/dashboard/access-control/view/:id" component={ViewRole} />
			    			<PrivateRoute path="/dashboard/access-control/search/:column/:value" component={ListRole} />

			    			<PrivateRoute path="/dashboard/purchase/list" component={ListPurchase} />
			    			<PrivateRoute path="/dashboard/purchase/view/:id" component={ViewPurchase} />
			    			<PrivateRoute path="/dashboard/purchase/logs-webhook/list" component={ListLogsWebhook} />
			    			
			    			{/*
			    			<PrivateRoute path="/dashboard/purchase/register" component={RegisterPurchase} />
			    			<PrivateRoute path="/dashboard/purchase/edit/:id" component={EditPurchase} />
			    			<PrivateRoute path="/dashboard/purchase/search/:column/:value" component={ListPurchase} />
			    			*/}

			    			<PrivateRoute path="/dashboard/profile/view" component={ViewProfile} />

			    			<PrivateRoute path="/error-page/:code" component={ErrorPage} />
							<Route component={ErrorPage} />

			    		</Switch>
		    		</div>
		    	</BrowserRouter>
		    </div>
		</>
	);
};

export default Main;
import React from "react";
import PurchasedProducts from "./PurchasedProducts";
import HeaderShoppingCart from "../../../../components/PanelToten/HeaderShoppingCart";
import "./style.css";

const ShoppingCart = () => {
  return (
    <div className="page-self-checkout">
      <HeaderShoppingCart />
      <PurchasedProducts />
    </div>
  );
};

export default ShoppingCart;

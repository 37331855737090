import React from 'react';

import {Route, Redirect} from 'react-router';

const PrivateRoutePanelToten = props => {
	const dataToten = JSON.parse(localStorage.getItem('dataToten'));
	const isLogged = !! dataToten?.token;
	
	return isLogged ? <Route {...props} /> : <Redirect to="../../panel-toten/login" />
}

export default PrivateRoutePanelToten;
import React from "react";
import "./style.css";
import imageCardCredit from "../../../assets/images/credit.png";
import imageCardPix from "../../../assets/images/pix.png";
import { ImCross } from "react-icons/im";

const SelectCard = ({ confirmPayment, total }) => {
  const showCardPayment = (e) => {
    confirmPayment("POS_STONE");
    let listCards = document.getElementById("modal-select-card");
    let areaCreditPayment = document.getElementById("area-read-card");
    listCards.style.display = "none";
    areaCreditPayment.style.display = "flex";
  };

  const showTransferencePix = (e) => {
    confirmPayment("PIX");
    let listCards = document.getElementById("modal-select-card");
    let areaCreditPayment = document.getElementById("area-transference-pix");
    listCards.style.display = "none";
    areaCreditPayment.style.display = "flex";
  };

  const closeModal = () => {
    document.getElementById("modal-select-card").style.display = "none";
  };

  return (
    <div className="modal-select-card" id="modal-select-card">
      <div className="area-list-cards">
        <label>Selecione a sua forma de pagamento</label>
        <button type="button" className="btn-close-modal" onClick={closeModal}>
          <ImCross />
        </button>
        <ul className="list-cards">
          <li onClick={(e) => showCardPayment(e)}>
            <img src={imageCardCredit} />
          </li>
          <li onClick={(e) => showTransferencePix(e)}>
            <img src={imageCardPix} />
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SelectCard;

import React from "react";

import { BrowserRouter, Route, Switch } from "react-router-dom";
import NotFound from "./pages/Error/index.jsx";
import Dashboard from "./pages/Dashboard/index.jsx";
import PanelToten from "./pages/PanelToten/index.jsx";
import LandscapePanelToten from "./pages/PanelToten/Landscape/index";
import PortraitPanelToten from "./pages/PanelToten/Portrait/index.jsx";

import LoginRoute from "./components/Route/loginRoute.jsx";
import Login from "./pages/Login/index.jsx";
import LoginPanelTotenRoute from "./pages/PanelToten/AuthUserStore/login";
import LogoutPanelTotenRoute from "./pages/PanelToten/AuthUserStore/login";
import PrivateRoute from "./components/Route/privateRoute.jsx";
import PrivateRoutePanelToten from "./components/Route/privateRoutePanelToten.jsx";

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <LoginRoute exact path="/" component={Login} />
      <Route path="/login" component={Login} />
      <Route exact path="/panel-toten" component={PanelToten} />
      <Route path="/panel-toten/login" component={PanelToten} />
      <PrivateRoutePanelToten
        path="/panel-toten/logout"
        component={PanelToten}
      />
      <Route path="/landscape/panel-toten" component={LandscapePanelToten} />
      <Route path="/portrait/panel-toten" component={PortraitPanelToten} />
      <PrivateRoute path="/dashboard" component={Dashboard} />
      <Route component={NotFound} />
    </Switch>
  </BrowserRouter>
);

export default Routes;

import React from 'react';
import './style.css';
import Main from '../../components/Dashboard/Main/index.jsx';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Dashboard = () => {

	return (
		<>
			<div className="area-dashboard">
				<ToastContainer
					position="top-right"
					autoClose={5000}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
				/>
				<Main></Main>
			</div>
		</>
	);
};

export default Dashboard;
import React, {useState, useEffect} from 'react';
import HeaderToten from '../Header/index.jsx';
import { Table } from 'react-bootstrap';
import apiService from '../../../../services/api.js';
import Loading from '../../../../components/Loading/index.jsx';
import { Can } from "react-access-level";
import { verifyError } from '../../../../services/actionsAppService.jsx';

const ViewToten = ({ match, location }) => {
	const api = apiService.get();
	const [toten, setToten] = useState([]);
	const [loading, setLoading] = useState(false);

	const {
	    params: { id }
	} = match;

	const viewToten = () => {
		setLoading(true);
	    api.get(`api/toten/${id}`)
		.then(resp => {
			setToten(resp.data.data.toten);
			setLoading(false);
		})
		.catch(error =>{
			setLoading(false);
			verifyError(error);
		})
	}

	
	useEffect(() => {
		viewToten()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	

	return (
		<>
			<div className="area-crud">
				<HeaderToten></HeaderToten>

				<Can 
					resource="toten"
					authority="toten-view"
					otherwiseComponent={<label>Sem acesso!</label>}
				>
					<h5 className="mb-3">Dados do toten</h5>

					{ (loading) ? (<Loading />) : (
						<Table striped bordered hover className='table-responsive'>
							<thead>
								<tr>
									<th>Coluna</th>
									<th>Valor</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Id</td>
									<td>{toten?.id}</td>
								</tr>
								<tr>
									<td>Nome</td>
									<td>{toten?.name}</td>
								</tr>
								<tr>
									<td>Status</td>
									<td>{toten?.status}</td>
								</tr>
								<tr>
									<td>Loja</td>
									<td>{toten?.store?.name}</td>
								</tr>
								<tr>
									<td>Email</td>
									<td>{toten?.email}</td>
								</tr>

								<tr>
									<td>Senha</td>
									<td>{toten?.password}</td>
								</tr>

								<tr>
									<td>Id do cliente</td>
									<td>{toten?.client_id}</td>
								</tr>

								<tr>
									<td>Token</td>
									<td>{toten?.token}</td>
								</tr>
							</tbody>
						</Table>
					)}
				</Can>
			</div>
		</>
	);
};

export default ViewToten;
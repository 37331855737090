import React, {useState, useEffect} from 'react';
import HeaderStore from '../Header/index.jsx';
import apiService from '../../../../services/api.js';
import { Can } from "react-access-level";
import { showNotify, verifyError } from '../../../../services/actionsAppService.jsx';
import PurchaseData from './Purchase.jsx';
import ListLogsWebhook from '../ListLogsWebhook/List.jsx';

const ViewPurchase = ({ match, location }) => {
	const api = apiService.get();
	const [purchase, setPurchase] = useState(null);
	const [loading, setLoading] = useState(false);

	const [logsWebhook, setLogsWebhook] = useState([]);
	const [loadingLogsWebhook, setLoadingLogsWebhook] = useState(false);
	const [offset, setOffset] = useState(1);
	const [qtdPages, setQtdPages] = useState(1);

	useEffect(() => {
		viewPurchase();
	}, [])

	useEffect(() => {
		purchase && listLogsWebhook();
	}, [purchase])

	const {
		params: { id }
	} = match;

	const viewPurchase = () => {
		setLoading(true);
	    api.get(`api/purchase/${id}`)
		.then(resp => {
			setPurchase(resp.data.data.purchase);
			setLoading(false);
		})
		.catch(error =>{
			setLoading(false);
			verifyError(error);
		})
	}

	const listLogsWebhook = () => {
		setLoadingLogsWebhook(true);
		api.get(`api/purchase/logs-webhook/${purchase.purchase_id}?page=${offset}`)
			.then(resp => {
			setLogsWebhook(resp.data.data.logsWebhook);
			setQtdPages(resp.data.data.pagination.pages);
			setLoadingLogsWebhook(false);
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			setLoadingLogsWebhook(false);
			verifyError(error);
		})
	}
	
	const renderListWebHooks = purchase && (<>
		<h5 className="mb-3">Logs da compra</h5>
		<ListLogsWebhook
			{...{
				logsWebhook,
				loading: loadingLogsWebhook,
				offset,
				setOffset,
				qtdPages
			}}
		/>
	</>)

	return (
		<>
			<div className="area-crud">
				<HeaderStore></HeaderStore>

				<Can 
					resource="purchase"
					authority="purchase-create"
					otherwiseComponent={<label>Sem acesso!</label>}
				>
					<h5 className="mb-3">Dados da compra</h5>

					<PurchaseData
						{...{
							purchase,
							loading
						}}
					/>

					{renderListWebHooks}
				</Can>
			</div>
		</>
	);
};

export default ViewPurchase;
import React, {useEffect} from 'react';
import { FiDelete } from 'react-icons/fi';
import { ImCross } from 'react-icons/im';
import ActionButton from "../ActionButton/index.jsx";

import './style.css';

const ModalInsertCPF = props => {

    const clickKeyboard = (event) => {
    	let inputCpf = document.getElementById("cpf");
    	if(inputCpf.value.length < 14){
	    	inputCpf.value += event.target.value;
	    	formataCPF(inputCpf);
	    	props.setCpf(inputCpf.value);
    	}
    }

    const deleteKeyboard = (event) => {
    	let inputCpf = document.getElementById("cpf");
    	if(inputCpf.value.length > 0) {
	    	inputCpf.value = inputCpf.value.substr(0, inputCpf.value.length-1);
	    }
    }

    const closeModal = () => {
    	props.close(false)
    	return false;
    }

    function formataCPF(cpf) {
		let cpfValue = cpf.value;

		if(cpfValue.length === 3 || cpfValue.length === 7) {
			cpf.value = cpfValue + '.';
		}else if(cpfValue.length === 11){
			cpf.value = cpfValue + '-';
		}
		
	}

	const confirmCpf = () => {
		let inputCpf = document.getElementById("cpf");
		if(inputCpf.value.length < 14){
			alert('CPF inválido! Lembrar de adicionar os pontos e traço.');
		}else{
			props.close(true)
			props.setCpfValidate(true);
		}
		
    	return false;
	}



	useEffect(() => {

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

	return (
		<div className="area-modal" id="modal-insert-cpf">
			<div className="modal-insert-cpf">
				<button type="button" className="btn-close-modal" onClick={closeModal}><ImCross /></button>
				<label>Digite seu CPF no teclado</label>
				<input type="text" className="cpf" id="cpf" maxlength="14" placeholder="000.000.000-00" />
				<div className="area-keyboard">
					<div className="row">
						<div className="col-4">
							<button type="button" className="btn-number" value="1" onClick={(event) => clickKeyboard(event)}>1</button>
						</div>
						<div className="col-4">
							<button type="button" className="btn-number" value="2" onClick={(event) => clickKeyboard(event)}>2</button>
						</div>
						<div className="col-4">
							<button type="button" className="btn-number" value="3" onClick={(event) => clickKeyboard(event)}>3</button>
						</div>
					</div>
					<div className="row">
						<div className="col-4">
							<button type="button" className="btn-number" value="4" onClick={(event) => clickKeyboard(event)}>4</button>
						</div>
						<div className="col-4">
							<button type="button" className="btn-number" value="5" onClick={(event) => clickKeyboard(event)}>5</button>
						</div>
						<div className="col-4">
							<button type="button" className="btn-number" value="6" onClick={(event) => clickKeyboard(event)}>6</button>
						</div>
					</div>
					
					<div className="row">
						<div className="col-4">
							<button type="button" className="btn-number" value="7" onClick={(event) => clickKeyboard(event)}>7</button>
						</div>
						<div className="col-4">
							<button type="button" className="btn-number" value="8" onClick={(event) => clickKeyboard(event)}>8</button>
						</div>
						<div className="col-4">
							<button type="button" className="btn-number" value="9" onClick={(event) => clickKeyboard(event)}>9</button>
						</div>
					</div>

					<div className="row">
						<div className="col-4">
							<button type="button" className="btn-number" value="0" onClick={(event) => clickKeyboard(event)}>0</button>
						</div>
						<div className="col-8">
							<button type="button" className="btn-delete-key" onClick={(event) => deleteKeyboard(event)}><FiDelete /></button>
						</div>
					</div>
				</div>

				<div className="area-btn-confirm-cpf">
					<div onClick={confirmCpf}>
						<ActionButton
							text="CONFIRMAR"
							type="button"
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ModalInsertCPF;
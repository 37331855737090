import React, {useState, useEffect} from 'react';
import { FiDelete } from 'react-icons/fi';
import { ImCross } from 'react-icons/im';
import { InputGroup, FormControl, Form, Button } from 'react-bootstrap';
import ActionButton from "../ActionButton/index.jsx";
import { showNotify } from '../../../services/actionsAppService.jsx';

import './style.css';

const ModalInsertCPF = props => {
    useEffect(() => {

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const clickKeyboard = (event) => {
    	let ticket = document.getElementById("ticket");
    	ticket.value += event.target.value;
		props.setChangedVoucher(false);
    	props.setVoucher(ticket.value);
    }

    const deleteKeyboard = (event) => {
    	let ticket = document.getElementById("ticket");
    	ticket.value = ticket.value.substr(0, ticket.value.length-1);
		props.setChangedVoucher(false);
    	props.setVoucher(ticket.value);
    }

    const closeModal = () => {
    	let inputVoucher = document.getElementById("ticket");
		props.setVoucher('');
		inputVoucher.value = '';
    	document.getElementById("modal-insert-ticket").style.display = "none";
    }

    const confirmVoucher = () => {
    	let inputVoucher = document.getElementById("ticket");
    	if(inputVoucher.value.length < 3){
    		showNotify('error', 'Número de caracteres invalido');
    	}else{
			document.getElementById("modal-insert-ticket").style.display = "none";
			props.setFilledVoucher(true);
			props.setChangedVoucher(true);
		}
    	return false;
	}

	useEffect(() => {
		if(props.changedVoucher)
    		props.resumePurchase();
	}, [props.changedVoucher]);

	return (
		<div className="area-modal" id="modal-insert-ticket">
			<div className="modal-insert-ticket">
				<button type="button" className="btn-close-modal" onClick={closeModal}><ImCross /></button>
				<label>Digite seu cupom no teclado</label>
				<input type="text" className="ticket" id="ticket" />
				<div className="area-keyboard">
					<div className="line-characters">
						<button type="button" className="btn-number" value="1" onClick={(event) => clickKeyboard(event)}>1</button>
						<button type="button" className="btn-number" value="2" onClick={(event) => clickKeyboard(event)}>2</button>
						<button type="button" className="btn-number" value="3" onClick={(event) => clickKeyboard(event)}>3</button>
						<button type="button" className="btn-number" value="4" onClick={(event) => clickKeyboard(event)}>4</button>
						<button type="button" className="btn-number" value="5" onClick={(event) => clickKeyboard(event)}>5</button>
						<button type="button" className="btn-number" value="6" onClick={(event) => clickKeyboard(event)}>6</button>
						<button type="button" className="btn-number" value="7" onClick={(event) => clickKeyboard(event)}>7</button>
						<button type="button" className="btn-number" value="8" onClick={(event) => clickKeyboard(event)}>8</button>
						<button type="button" className="btn-number" value="9" onClick={(event) => clickKeyboard(event)}>9</button>
						<button type="button" className="btn-number" value="0" onClick={(event) => clickKeyboard(event)}>0</button>
					</div>

					<div className="line-characters">
						<button type="button" className="btn-char" value="q" onClick={(event) => clickKeyboard(event)}>q</button>
						<button type="button" className="btn-char" value="w" onClick={(event) => clickKeyboard(event)}>w</button>
						<button type="button" className="btn-char" value="e" onClick={(event) => clickKeyboard(event)}>e</button>
						<button type="button" className="btn-char" value="r" onClick={(event) => clickKeyboard(event)}>r</button>
						<button type="button" className="btn-char" value="t" onClick={(event) => clickKeyboard(event)}>t</button>
						<button type="button" className="btn-char" value="y" onClick={(event) => clickKeyboard(event)}>y</button>
						<button type="button" className="btn-char" value="u" onClick={(event) => clickKeyboard(event)}>u</button>
						<button type="button" className="btn-char" value="i" onClick={(event) => clickKeyboard(event)}>i</button>
						<button type="button" className="btn-char" value="o" onClick={(event) => clickKeyboard(event)}>o</button>
						<button type="button" className="btn-char" value="p" onClick={(event) => clickKeyboard(event)}>p</button>
					</div>

					<div className="line-characters">
						<button type="button" className="btn-char" value="a" onClick={(event) => clickKeyboard(event)}>a</button>
						<button type="button" className="btn-char" value="s" onClick={(event) => clickKeyboard(event)}>s</button>
						<button type="button" className="btn-char" value="d" onClick={(event) => clickKeyboard(event)}>d</button>
						<button type="button" className="btn-char" value="f" onClick={(event) => clickKeyboard(event)}>f</button>
						<button type="button" className="btn-char" value="g" onClick={(event) => clickKeyboard(event)}>g</button>
						<button type="button" className="btn-char" value="h" onClick={(event) => clickKeyboard(event)}>h</button>
						<button type="button" className="btn-char" value="j" onClick={(event) => clickKeyboard(event)}>j</button>
						<button type="button" className="btn-char" value="k" onClick={(event) => clickKeyboard(event)}>k</button>
						<button type="button" className="btn-char" value="l" onClick={(event) => clickKeyboard(event)}>l</button>
					</div>

					<div className="line-characters">
						<button type="button" className="btn-char" value="z" onClick={(event) => clickKeyboard(event)}>z</button>
						<button type="button" className="btn-char" value="x" onClick={(event) => clickKeyboard(event)}>x</button>
						<button type="button" className="btn-char" value="c" onClick={(event) => clickKeyboard(event)}>c</button>
						<button type="button" className="btn-char" value="v" onClick={(event) => clickKeyboard(event)}>v</button>
						<button type="button" className="btn-char" value="b" onClick={(event) => clickKeyboard(event)}>b</button>
						<button type="button" className="btn-char" value="n" onClick={(event) => clickKeyboard(event)}>n</button>
						<button type="button" className="btn-char" value="m" onClick={(event) => clickKeyboard(event)}>m</button>
						<button type="button" className="btn-delete-key landscape" onClick={(event) => deleteKeyboard(event)}><FiDelete /></button>
					</div>

					<div className="line-characters">
						<button type="button" className="btn-delete-key portrait" onClick={(event) => deleteKeyboard(event)}><FiDelete /></button>
					</div>
				</div>

				<div className="area-btn-confirm-ticket">
					<div onClick={confirmVoucher}>
						<ActionButton
							text="CONFIRMAR"
							type="button"
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ModalInsertCPF;
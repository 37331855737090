import React, {useState, useEffect} from 'react';
import HeaderStore from '../Header/index.jsx';
import { Button, Form, Row, Col } from 'react-bootstrap';
import apiService from '../../../../services/api.js';
import apiLocations from '../../../../services/apiLocations.js';
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import Loading from '../../../../components/Loading/index.jsx';
import { Can } from "react-access-level";
import { showNotify, verifyError } from '../../../../services/actionsAppService.jsx';

const EditStore = ({ match, location }) => {
	const {
	    params: { id }
	} = match;
	const api = apiService.get()
	const history = useHistory();
	const [users, setUsers] = useState([]);
	const [states, setStates] = useState([]);
	const [cities, setCities] = useState([]);
	const [errors, setErrors] = useState('');
	const [loading, setLoading] = useState(false);

	const { register, handleSubmit, setValue, getValues } = useForm();

	const initStore = () => {
		setLoading(true);
		api.get(`api/store/${id}`)
		.then(resp => {
			const data = resp.data.data.store;
			setValue("name", data.name);
			setValue("cnpj", data.cnpj);
			setValue("user_id", data.user_id);
			setValue("zipcode", data.address.zipcode);
			setValue("state_id", data.address.state_id);
			setValue("city_id", data.address.city_id);
			setValue("street", data.address.street);
			setValue("neighborhood", data.address.neighborhood);
			setValue("number", data.address.number);
			setValue("complement", data.address.complement);
			setValue("api_store_id", data.api_store_id);
			setValue("api_domain_id", data.api_domain_id);
			setLoading(false);
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			setErrors(error.message)
			verifyError(error);
		})
	}

	const onUpdate = data => {
		setLoading(true);
		data.state = states.find(function(el){
			return el.id == data.state_id;
		})?.nome;

		data.city = cities.find(function(el){
			return el.id == data.city_id;
		})?.nome;

		data.zipcode = parseInt(data.zipcode);

		api.put(`api/store/${id}`, data)
		.then(resp => {
			showNotify('success', 'Loja atualizado com sucesso!');
			setLoading(false);
			history.push('/dashboard/store/list');
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			setErrors(error?.response?.data?.data?.errors)
			setLoading(false);
			verifyError(error);
		})
	}

	const onChangeState = (ev) => {
		const { value } = ev.target;
		setValue("state_id", value);
		initCities(value);
	}

	const initUsers = () => {
		api.get('api/user/all')
		.then(resp => {
			const data = resp.data.data.users;
			setUsers(data)
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			setErrors(error.message)
			verifyError(error);
		})
	}

	const initStates = () => {
	    apiLocations.get('estados')
		.then((res) => {
			setStates(res.data)
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			console.log(error);
		})

	}

	const initCities = (idState) => {
		apiLocations.get(`estados/${idState}/municipios`)
		.then((res) => {
			setCities(res.data)
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			console.log(error);
		})
	}

	useEffect(() => {
		if(!getValues("state_id")){
			initStore();
			initUsers();
			initStates();
		}else{
			initCities(getValues("state_id"));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getValues("state_id")]);


	return (
		<>
			<div className="area-crud">
				<HeaderStore></HeaderStore>

				<Can 
					resource="store"
					authority="store-update"
					otherwiseComponent={<label>Sem acesso!</label>}
				>
					<h5 className="mb-3">Formulário de edição</h5>

					{ (loading) ? (<Loading />) : (null)}

					<Form onSubmit={handleSubmit(onUpdate)} className="form-register">
						<Row className="mb-3">
							<Col sm="4">
								<Form.Group>
								    <label htmlFor="name" className="form-label">Nome:</label>
								    <input type="text" name="name" className="form-control" id="name" {...register("name")} />
								    <span className="error-message">
								    	{errors?.name ? errors.name[0] : null}
								    </span>
								</Form.Group>
							</Col>
							<Col sm="4">
								<Form.Group>
								    <label htmlFor="cnpj" className="form-label">CNPJ:</label>
								    <input type="text" name="cnpj" className="form-control" id="cnpj" {...register("cnpj")} />
								    <span className="error-message">{errors?.cnpj ? errors.cnpj[0] : null}</span>
								</Form.Group>
							</Col>
							<Col sm="4">
								<Form.Group>
								    <label className="form-label">Usuário:</label>
								    <select className="form-select" name="user_id" {...register("user_id")} >
								    	<option value="">Selecionar</option>
								    	{users?.map((user, index) => (
											<option selected={user.id === getValues("user_id")} value={user.id} key={index}>{user.name}</option>
								    	))}
							        </select>
								    
								    <span className="error-message">{errors?.user_id ? errors.user_id[0] : null}</span>
								</Form.Group>
							</Col>
						</Row>

						<Row className="mb-3">
							<Col sm="3">
								<Form.Group>
								    <label htmlFor="zipcode" className="form-label">CEP:</label>
								    <input type="number" maxLength="8" name="zipcode" className="form-control" id="zipcode" {...register("zipcode")} />
								    <span className="error-message">{errors?.zipcode ? errors.zipcode[0] : null}</span>
								</Form.Group>
							</Col>
							<Col sm="4">
								<Form.Group>
								    <label htmlFor="state" className="form-label">Estado:</label>
								    <select className="form-select" name="state_id" {...register("state_id")} onChange={(ev) => onChangeState(ev)} >
								    	<option value="">Selecionar</option>
								    	{states?.map((state, index) => (
											<option selected={state.id === getValues("state_id")} value={state.id} key={index}>{state.sigla}</option>
								    	))}
							        </select>

								    <span className="error-message">{errors?.state_id ? errors.state_id[0] : null}</span>
								</Form.Group>
							</Col>
							<Col sm="5">
								<Form.Group>
								    <label htmlFor="city" className="form-label">Cidade:</label>
								    <select className="form-select" name="city_id" {...register("city_id")} >
								    	<option value="">Selecionar</option>
								    	{cities?.map((city, index) => (
											<option selected={city.id === getValues("city_id")} value={city.id} key={index}>{city.nome}</option>
								    	))}
							        </select>
								    <span className="error-message">{errors?.city_id ? errors.city_id[0] : null}</span>
								</Form.Group>
							</Col>
						</Row>
						
						<Row className="mb-3">
							<Col sm="5">
								<Form.Group controlId="formBasicEmail">
								    <label htmlFor="street" className="form-label">Endereço:</label>
								    <input type="text" name="street" className="form-control" id="street" {...register("street")} />
								    <span className="error-message">{errors?.street ? errors.street[0] : null}</span>
								</Form.Group>
							</Col>
							<Col sm="5">
								<Form.Group controlId="formBasicEmail">
								    <label htmlFor="neighborhood" className="form-label">Bairro:</label>
								    <input type="text" name="neighborhood" className="form-control" id="neighborhood" {...register("neighborhood")} />
								    <span className="error-message">{errors?.neighborhood ? errors.neighborhood[0] : null}</span>
								</Form.Group>
							</Col>
							<Col sm="2">
								<Form.Group controlId="formBasicEmail">
								    <label htmlFor="number" className="form-label">Nº:</label>
								    <input type="text" name="number" maxLength="6" className="form-control" id="number" {...register("number")} />
								    <span className="error-message">{errors?.number ? errors.number[0] : null}</span>
								</Form.Group>
							</Col>
						</Row>

						<Row className="mb-3">
							<Col sm="12">
								<Form.Group>
								    <label htmlFor="complement" className="form-label">Complemento:</label>
								    <input type="text" name="complement" className="form-control" id="complement" {...register("complement")} />
								    <span className="error-message">{errors?.complement ? errors.complement[0] : null}</span>
								</Form.Group>
							</Col>
						</Row>

						<Row className="mb-3">
							<Col sm="6">
								<Form.Group>
								    <label htmlFor="api_store_id" className="form-label">ID API da Loja:</label>
								    <input type="number" name="api_store_id" className="form-control" id="api_store_id" {...register("api_store_id")} />
								    <span className="error-message">{errors?.api_store_id ? errors.api_store_id[0] : null}</span>
								</Form.Group>
							</Col>

							<Col sm="6">
								<Form.Group>
								    <label htmlFor="api_domain_id" className="form-label">ID do domínio:</label>
								    <input type="number" name="api_domain_id" className="form-control" id="api_domain_id" {...register("api_domain_id")} />
								    <span className="error-message">{errors?.api_domain_id ? errors.api_domain_id[0] : null}</span>
								</Form.Group>
							</Col>
						</Row>

					    <Row className="mb-3">
					    	<Col>
					    		<Button type="submit" className="btn">Salvar</Button>
					    	</Col>
					    </Row>
					    
					</Form>
				</Can>
			</div>
		</>
	);
};

export default EditStore;
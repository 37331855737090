import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./style.css";

import { ToastContainer } from "react-toastify";

import Home from "./Home/index.jsx";
import ShoppingCart from "./ShoppingCart/index.jsx";
import FinishPayment from "./FinishPayment/index.jsx";

import PrivateRoutePanelToten from "../../../components/Route/privateRoutePanelToten";
import ErrorPage from "../../Error/index";

const PortraitPanelToten = () => {
  return (
    <div className="area-toten-panel-portrait">
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <BrowserRouter>
        <Switch>
          <PrivateRoutePanelToten
            path="/portrait/panel-toten"
            exact
            component={Home}
          />
          <PrivateRoutePanelToten
            path="/portrait/panel-toten/shopping-cart"
            exact
            component={ShoppingCart}
          />
          <PrivateRoutePanelToten
            path="/portrait/panel-toten/finish-payment"
            exact
            component={FinishPayment}
          />
          <Route component={ErrorPage} />
        </Switch>
      </BrowserRouter>
    </div>
  );
};

export default PortraitPanelToten;

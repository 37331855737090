import React from "react";
import "./style.css";
import { Link } from "react-router-dom";
import logo2 from "../../../../assets/images/logo-2.png";
import Footer from "../../../../components/PanelToten/Footer/index.jsx";
import ActionButton from "../../../../components/PanelToten/ActionButton/index.jsx";

const HomeToten = () => {
  return (
    <div className="wrapper-landscape">
      <div className="page-home-landscape">
        <img className="logo-landscape" src={logo2} />
        <Link className="btn-init-landscape" to="/landscape/panel-toten/shopping-cart">
          <ActionButton type="button" text="Iniciar" background="#dce169" />
        </Link>
      </div>
      <Footer />
    </div>
  );
};

export default HomeToten;

import React from "react";
import { BiSearch } from "react-icons/bi";
import { HiChevronRight } from "react-icons/hi";
import { GiConfirmed } from "react-icons/gi";
import { InputGroup, FormControl, Form, Button } from "react-bootstrap";
import ActionButton from "../../../../../components/PanelToten/ActionButton";
import ModalInsertCPF from "../../../../../components/PanelToten/ModalInsertCPF";
import ModalInsertTicket from "../../../../../components/PanelToten/ModalInsertTicket";
import SelectCard from "../../../../../components/PanelToten/SelectCard";
import ProgressBar from "../../../../../components/PanelToten/ProgressBar";
import ReadBarCode from "../../../../../components/PanelToten/ReadBarCode";
import "./style.css";
import CartFactory from "../../../CartFactory";
import ModalLoadingPayment from "../../../../../components/PanelToten/ModalLoadingPayment";

const PurchasedProducts = () => {
  const {
    shouldIntervalBeCancelled,
    VerifyPixPayment,
    countLoop,
    cancelPurchase,
    newTryVerify,
    selectProduct,
    products,
    removeProduct,
    addProduct,
    decreaseProduct,
    handleSubmit,
    changeCode,
    finishPayment,
    setCpf,
    setCpfValidate,
    closeInputCpf,
    setVoucher,
    changedVoucher,
    setChangedVoucher,
    setFilledVoucher,
    verifyVoucher,
    resumePurchase,
    showInputCPF,
    cpfValidate,
    showInputTicket,
    StatusVerifyVoucher,
    resumePurchaseRequest,
    priceTotal,
    voucher,
    removeVoucher,
    confirmPayment,
    resetTimeOperation,
    setResetTimeOperation
  } = CartFactory({ screenRotation: "landscape" });

  return (
    <>
      <div className="area-cart-landscape">
        <ModalLoadingPayment
          {...{
            shouldIntervalBeCancelled,
            countLoop,
            cancelPurchase,
            newTryVerify,
            VerifyPixPayment,
            priceTotal
          }}
        />
        <Form
          className="form-search-product"
          id="form-search-product"
          onSubmit={selectProduct}
        >
          <InputGroup className="mb-3 input-search">
            <FormControl
              type="text"
              placeholder="Busque um produto"
              inputmode="none"
              id="search-product-code"
              onChange={(ev) => changeCode(ev)}
            />
            <Button className="btn btn-dark" type="submit">
              <BiSearch />
            </Button>
          </InputGroup>
        </Form>

        <ul id="list-products">
          {products.map((product, index) => (
            <li key={index}>
              <div className="row">
                <div className="col-3">
                  <img src={product.image} />
                </div>
                <div className="col-9 justify-content-space-between">
                  <div className="row">
                    <div className="col-8">
                      <div className="area-name-product">
                        <label>{product.name}</label>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="area-price-product">
                        <span>R$ {product.price.toFixed(2)}</span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <div className="area-btn-remove-product">
                        <button onClick={(e) => removeProduct(e, index)}>
                          Remover produto
                        </button>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="area-qtd-product">
                        <label>Qtd.</label>
                        <button
                          type="button"
                          onClick={(e) => addProduct(e, index)}
                          className="btn-alter-qtd-prod"
                        >
                          <span>+</span>
                        </button>
                        <input type="text" value={product.count} />
                        <button
                          type="button"
                          onClick={(e) => decreaseProduct(e, index)}
                          className="btn-alter-qtd-prod"
                        >
                          <span>-</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
        <ReadBarCode />
      </div>

      <div
        className="area-form-checkout-landscape"
        id="area-form-checkout-landscape"
      >
        <Form onSubmit={handleSubmit(finishPayment)}>
          <ModalInsertCPF
            setCpf={setCpf}
            setCpfValidate={setCpfValidate}
            close={closeInputCpf}
          />
          <ModalInsertTicket
            setVoucher={setVoucher}
            changedVoucher={changedVoucher}
            setFilledVoucher={setFilledVoucher}
            setChangedVoucher={setChangedVoucher}
            verifyVoucher={verifyVoucher}
            resumePurchase={resumePurchase}
          />

          <div className="area-price">
            <label>
              {resumePurchaseRequest?.settings?.voucher?.active
                ? resumePurchaseRequest?.values[0]?.name
                : "Subtotal"}
            </label>
            <div>
              {resumePurchaseRequest?.settings?.voucher?.active && (
                <span className="without-discount">
                  {resumePurchaseRequest.values[0]?.value}
                </span>
              )}
              <span>{priceTotal}</span>
            </div>
          {/* {resumePurchaseRequest?.settings?.voucher?.active ? (
            <div>
              <label>
                <u>{voucher}</u>
                <button type="button" onClick={removeVoucher}>
                  <RiDeleteBinLine />
                </button>
              </label>
            </div>
          ) : (
            ""
          )} */}
          </div>

          <div className="area-buttons">
            <button type="button" className="button" onClick={showInputCPF}>
              CPF
              {cpfValidate ? (
                <GiConfirmed size="30" />
              ) : (
                <HiChevronRight size="30" />
              )}
            </button>
            <ActionButton type="submit" text="FINALIZAR" background="#dce169" />
            <button type="button" className="button" onClick={showInputTicket}>
              CUPOM <StatusVerifyVoucher />
            </button>
          </div>
        </Form>
      </div>

      <SelectCard confirmPayment={confirmPayment} total={priceTotal} />

      <ProgressBar resetTimeOperation={resetTimeOperation} setResetTimeOperation={setResetTimeOperation} />
    </>
  );
};

export default PurchasedProducts;

import React, {useState, useEffect} from 'react';
import HeaderStore from '../Header/index.jsx';
import { Button, Form, Row, Col } from 'react-bootstrap';
import apiService from '../../../../services/api.js';
import apiLocations from '../../../../services/apiLocations.js';
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import InputMask from "react-input-mask";
import Loading from '../../../../components/Loading/index.jsx';
import { Can } from "react-access-level";
import { showNotify, verifyError } from '../../../../services/actionsAppService.jsx';

const RegisterStore = () => {
	const api = apiService.get()
	const history = useHistory();
	const [users, setUsers] = useState([]);
	const [states, setStates] = useState([]);
	const [cities, setCities] = useState([]);
	const [errors, setErrors] = useState('');
	const [loading, setLoading] = useState(false);

	const { register, handleSubmit } = useForm();

	const onSubmit = data => {
		setLoading(true);
		data.state = states.find(function(el){
		    return el.id == data.state_id;
		})?.nome;

		data.city = cities.find(function(el){
		    return el.id == data.city_id;
		})?.nome;
		data.zipcode = parseInt(data.zipcode);
		
		api.post('api/store', data)
		.then(resp => {
			showNotify('success', 'Loja registrada com sucesso!');
			setLoading(false);
			history.push('/dashboard/store/list');
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			setErrors(error?.response?.data?.data?.errors)
			setLoading(false);
			verifyError(error);
		})
	}

	const onChangeState = (ev) => {
		const { value } = ev.target;
		
		apiLocations.get(`estados/${value}/municipios`)
		.then((res) => {
			setCities(res.data);
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			console.log(error);
		})
	}

	const initUsers = () => {
		api.get('api/user/all')
		.then(resp => {
			setUsers(resp.data.data.users);
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			setErrors(error.message)
			verifyError(error);
		})
	}


	const initStates = () => {
	    apiLocations.get('estados')
		.then((res) => {
			setStates(res.data);
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			console.log(error);
		})
	}

	useEffect(() => {
		initUsers()
		initStates()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<>	
			<div className="area-crud">
				<HeaderStore></HeaderStore>

				<Can 
					resource="store"
					authority="store-create"
					otherwiseComponent={<label>Sem acesso!</label>}
				>
					<h5 className="mb-3">Formulário de registro</h5>

					{ (loading) ? (<Loading />) : (null)}

					<Form onSubmit={handleSubmit(onSubmit)} className="form-register">
						<Row className="mb-3">
							<Col sm="4">
								<Form.Group>
								    <label htmlFor="name" className="form-label">Nome:</label>
								    <input type="text" name="name" className="form-control" id="name" {...register("name")} />
								    <span className="error-message">{errors?.name ? errors.name[0] : null}</span>
								</Form.Group>
							</Col>
							<Col sm="4">
								<Form.Group>
								    <label htmlFor="cnpj" className="form-label">CNPJ:</label>
								    <InputMask mask="99.999.999/9999-99" name="cnpj" className="form-control" id="cnpj" {...register("cnpj")} />
								    <span className="error-message">{errors?.cnpj ? errors.cnpj[0] : null}</span>
								</Form.Group>
							</Col>
							<Col sm="4">
								<Form.Group>
								    <label htmlFor="cnpj" className="form-label">Usuário:</label>
								    <select className="form-select" name="user_id" {...register("user_id")}>
								    	<option value="">Selecionar</option>
							            {users?.map((user, index) => (
								    		<option value={user.id} key={index}>{user.name}</option>
								    	))}
							        </select>
								    <span className="error-message">{errors?.user_id ? errors.user_id[0] : null}</span>
								</Form.Group>
							</Col>
						</Row>

						<Row className="mb-3">
							<Col sm="3">
								<Form.Group>
								    <label htmlFor="zipcode" className="form-label">CEP:</label>
								    <InputMask mask="99999999" name="zipcode" className="form-control" id="zipcode" {...register("zipcode")} />
								    <span className="error-message">{errors?.zipcode ? errors.zipcode[0] : null}</span>
								</Form.Group>
							</Col>
							<Col sm="4">
								<Form.Group>
								    <label htmlFor="state" className="form-label">Estado:</label>
								    <select className="form-select" name="state_id" {...register("state_id")} onChange={(ev) => onChangeState(ev)} >
								    	<option value="">Selecionar</option>
								    	{states?.map((state, index) => (
								    		<option value={state.id} key={index}>{state.sigla}</option>
								    	))}
							        </select>
								    <span className="error-message">{errors?.state_id ? errors.state_id[0] : null}</span>
								</Form.Group>
							</Col>
							<Col sm="5">
								<Form.Group>
								    <label htmlFor="city" className="form-label">Cidade:</label>
								    <select className="form-select" name="city_id" {...register("city_id")} >
								    	<option value="">Selecionar</option>
							            {cities?.map((city, index) => (
								    		<option value={city.id} key={index}>{city.nome}</option>
								    	))}
							        </select>
								    <span className="error-message">{errors?.city_id ? errors.city_id[0] : null}</span>
								</Form.Group>
							</Col>
						</Row>
						
						<Row className="mb-3">
							<Col sm="5">
								<Form.Group controlId="formBasicEmail">
								    <label htmlFor="street" className="form-label">Endereço:</label>
								    <input type="text" name="street" className="form-control" id="street" {...register("street")} />
								    <span className="error-message">{errors?.street ? errors.street[0] : null}</span>
								</Form.Group>
							</Col>
							<Col sm="5">
								<Form.Group controlId="formBasicEmail">
								    <label htmlFor="neighborhood" className="form-label">Bairro:</label>
								    <input type="text" name="neighborhood" className="form-control" id="neighborhood" {...register("neighborhood")} />
								    <span className="error-message">{errors?.neighborhood ? errors.neighborhood[0] : null}</span>
								</Form.Group>
							</Col>
							<Col sm="2">
								<Form.Group controlId="formBasicEmail">
								    <label htmlFor="number" className="form-label">Nº:</label>
								    <input type="text" name="number" maxlength="6" className="form-control" id="number" {...register("number")} />
								    <span className="error-message">{errors?.number ? errors.number[0] : null}</span>
								</Form.Group>
							</Col>
						</Row>

						<Row className="mb-3">
							<Col sm="12">
								<Form.Group>
								    <label htmlFor="complement" className="form-label">Complemento:</label>
								    <input type="text" name="complement" className="form-control" id="complement" {...register("complement")} />
								    <span className="error-message">{errors?.complement ? errors.complement[0] : null}</span>
								</Form.Group>
							</Col>
						</Row>

						<Row className="mb-3">
							<Col sm="6">
								<Form.Group>
								    <label htmlFor="api_store_id" className="form-label">ID API da Loja:</label>
								    <input type="number" name="api_store_id" className="form-control" id="api_store_id" {...register("api_store_id")} />
								    <span className="error-message">{errors?.api_store_id ? errors.api_store_id[0] : null}</span>
								</Form.Group>
							</Col>

							<Col sm="6">
								<Form.Group>
								    <label htmlFor="api_domain_id" className="form-label">ID do domínio:</label>
								    <input type="number" name="api_domain_id" className="form-control" id="api_domain_id" {...register("api_domain_id")} />
								    <span className="error-message">{errors?.api_domain_id ? errors.api_domain_id[0] : null}</span>
								</Form.Group>
							</Col>
						</Row>

					    <Row className="mb-3">
					    	<Col>
					    		<Button type="submit" className="btn">Cadastrar</Button>
					    	</Col>
					    </Row>
					    
					</Form>
				</Can>
			</div>
		</>
	);
};

export default RegisterStore;
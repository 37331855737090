import React from "react";
import { HiChevronLeft } from "react-icons/hi";
import PurchasedProducts from "./PurchasedProducts/index.jsx";
import { Link } from "react-router-dom";
import "./style.css";

const ShoppingCart = () => {
  return (
    <div className="page-self-checkout-landscape">
      <div className="top-header">
        <Link to="../panel-toten">
          <HiChevronLeft size={45} /> VOLTAR
        </Link>
      </div>
      <PurchasedProducts />
    </div>
  );
};

export default ShoppingCart;

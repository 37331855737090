import React from 'react';
import { NavLink } from 'react-router-dom';
import { BiTachometer, BiGroup, BiStoreAlt, BiMenu, BiDesktop, BiBasket, BiLock, BiUser } from "react-icons/bi";
import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarHeader,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
} from 'cdbreact';

import { Can, or } from "react-access-level";

import './style.css';


const Sidebar = () => {
	return (
		<>
    		<div style={{ display: 'flex', height: '100vh', overflow: 'scroll initial' }}>
		    	<CDBSidebar textColor="#fff" backgroundColor="#17202a">
			        <CDBSidebarHeader className="link-top-sidebar" prefix={<BiMenu />}>
			          <a href="/dashboard" className="text-decoration-none">Painel Zaitt</a>
			        </CDBSidebarHeader>

			        <CDBSidebarContent className="sidebar-content">
			          <CDBSidebarMenu>
			            
			            <Can match={or("dashboard:dashboard-view", "dashboard:dashboard-delete", "dashboard:dashboard-update", "dashboard:dashboard-create")}>
				            <NavLink exact to="/dashboard" activeClassName="activeClicked">
				            	<CDBSidebarMenuItem className="link-sidebar"><BiTachometer />Dashboard</CDBSidebarMenuItem>
				            </NavLink>
			            </Can>

			            <Can match={or("user:user-view", "user:user-delete", "user:user-update", "user:user-create")}>
				            <NavLink to="/dashboard/user/list" activeClassName="activeClicked">
				            	<CDBSidebarMenuItem className="link-sidebar"><BiGroup />Usuários</CDBSidebarMenuItem>
				            </NavLink>
			            </Can>

			            <Can match={or("store:store-view", "store:store-delete", "store:store-update", "store:store-create")}>
				            <NavLink to="/dashboard/store/list" activeClassName="activeClicked">
				            	<CDBSidebarMenuItem className="link-sidebar"><BiStoreAlt />Lojas</CDBSidebarMenuItem>
				            </NavLink>
			            </Can>

			            <Can match={or("toten:toten-view", "toten:toten-delete", "toten:toten-update", "toten:toten-create")}>
				            <NavLink to="/dashboard/toten/list" activeClassName="activeClicked">
				            	<CDBSidebarMenuItem className="link-sidebar"><BiDesktop />Totens</CDBSidebarMenuItem>
				            </NavLink>
			            </Can>

			            <Can match={or("purchase:purchase-view", "purchase:purchase-delete", "purchase:purchase-update", "purchase:purchase-create")}>
				            <NavLink to="/dashboard/purchase/list" activeClassName="activeClicked">
				            	<CDBSidebarMenuItem className="link-sidebar"><BiBasket />Compras</CDBSidebarMenuItem>
				            </NavLink>
			            </Can>

			            <Can match={or("acl:acl-view", "acl:acl-delete", "acl:acl-update", "acl:acl-create")}>
				            <NavLink to="/dashboard/access-control/list" activeClassName="activeClicked">
				            	<CDBSidebarMenuItem className="link-sidebar"><BiLock />Controle de acesso</CDBSidebarMenuItem>
				            </NavLink>
			            </Can>

			            <NavLink to="/dashboard/profile/view" activeClassName="activeClicked">
			            	<CDBSidebarMenuItem className="link-sidebar"><BiUser />Meu perfil</CDBSidebarMenuItem>
			            </NavLink>

			          </CDBSidebarMenu>
			        </CDBSidebarContent>
		    	</CDBSidebar>
		    </div>
		</>
	);
};

export default Sidebar;
import React, { useState, useEffect } from "react";
import { HiChevronRight } from "react-icons/hi";
import { GiConfirmed } from "react-icons/gi";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { useForm } from "react-hook-form";
import apiService from "../../services/api";
import { showNotify, verifyError } from "../../services/actionsAppService";
import { useHistory } from "react-router-dom";

const CartFactory = ({screenRotation}) => {
  const api = apiService.get();
  const history = useHistory();
  const [code, setCode] = useState("");
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [priceTotal, setPriceTotal] = useState("R$ 0,00");
  const { handleSubmit } = useForm();
  const [cpf, setCpf] = useState("");
  const [cpfValidate, setCpfValidate] = useState(false);
  const [voucher, setVoucher] = useState("");
  const [filledVoucher, setFilledVoucher] = useState(false);
  const [changedVoucher, setChangedVoucher] = useState(false);
  const [validateVoucher, setValidateVoucher] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [resumePurchaseRequest, setResumePurchaseRequest] = useState({});
  const [savePurchaseRequest, setSavePurchaseRequest] = useState({});
  const [savePurchasePixPayment, setSavePurchasePixPayment] = useState({});
  const [resetTimeOperation, setResetTimeOperation] = useState(false);

  const [countTime, setCountTime] = useState(0);
  const [countTimeLimit, setCountTimeLimit] = useState(30);
  const [countLoop, setCountLoop] = useState(2);
  const [intervalID, setIntervalID] = useState(0);
  const [shouldIntervalBeCancelled, setShouldIntervalBeCancelled] =
    useState(false);
  const [wantsToFinish, setWantsToFinish] = useState(false);

  let store = JSON.parse(localStorage.getItem("dataStore"));
  let toten = JSON.parse(localStorage.getItem("dataToten"));

  const selectProduct = async (event) => {
    event?.preventDefault();

    console.log(document.getElementById("search-product-code").value);

    api
      .get(
        `api/self-checkout/${store.api_store_id}/${store.api_domain_id}/${toten.id}/search/${code}`
      ) //api loja id - domain id - codebar
      .then((resp) => {
        console.log(resp.data.data.product);
        if (resp.data.data.product.length === 0) {
          showNotify("error", "Produto não encontrado");
        } else {
          insertProduct(resp.data.data.product);
          document.getElementById("search-product-code").value = "";
          setCode("");
        }
      })
      .catch((error) => {
        verifyError(error);
        document.getElementById("search-product-code").value = "";
        setCode("");
      });
  };

  const insertProduct = (product) => {
    product.count = 1;
    setProducts([product, ...products]);
  };

  const removeProduct = (event, i) => {
    resumePurchase();
    setProducts([
      ...products?.filter(function (elem, index) {
        return index !== parseInt(i);
      }),
    ]);
  };

  const changeCode = (ev) => {
    console.log("🚀 ~ file: index.jsx ~ line 94 ~ changeCode ~ ev", ev);
    setCode(ev.target.value.trim());
  };

  const showInputCPF = () => {
    document.getElementById("modal-insert-cpf").style.display = "flex";
  };

  const closeInputCpf = (cpfValidate) => {
    document.getElementById("modal-insert-cpf").style.display = "none";
    if (wantsToFinish && cpfValidate) openAreaSelectPayment();
  };

  const showInputTicket = () => {
    document.getElementById("modal-insert-ticket").style.display = "flex";
  };

  const addProduct = (e, index) => {
    let listTemp = products;
    if (listTemp[index].count < 100) {
      listTemp[index].count += 1;
    }
    setProducts(listTemp);
    resumePurchase();
  };

  const decreaseProduct = (e, index) => {
    let listTemp = products;
    if (listTemp[index].count > 1) {
      listTemp[index].count -= 1;
    }
    setProducts(listTemp);
    resumePurchase();
  };

  const resumePurchase = async () => {
    let productsFinish = [];
    let selfCheckout = {};
    setLoading(true);

    products.forEach(function (item) {
      productsFinish.push({
        opcoes: [],
        gramatura: 1000,
        id: item.id,
        complements: [],
        quantidade: item.count,
        adicionais: [],
        observacao: null,
      });
    });

    selfCheckout = {
      loja_id: store?.api_store_id,
      produtos: productsFinish,
      voucher: voucher.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, ""),
      metodo_pagamento: paymentMethod,
      cliente_id: toten.client_id,
      cpf: cpf.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, ""),
    };

    api
      .post(
        `api/self-checkout/${store?.api_store_id}/${store?.api_domain_id}/${toten?.id}/resume-purchase`,
        selfCheckout
      )
      .then((resp) => {
        setLoading(false);
        setResumePurchaseRequest(resp.data.data.resumePurchase);
        verifyVoucher(resp.data.data.resumePurchase);
        console.log(resp.data.data.resumePurchase);
        setPriceTotal(resp.data.data.resumePurchase.total.value);
      })
      .catch((error) => {
        setLoading(false);
        verifyError(error);
      });
  };

  const savePurchase = async (typePayment) => {
    let productsFinish = [];
    let selfCheckout = {};

    setLoading(true);

    products.forEach(function (item) {
      productsFinish.push({
        opcoes: [],
        gramatura: 1000,
        id: item.id,
        complements: [],
        quantidade: item.count,
        adicionais: [],
        observacao: null,
      });
    });

    selfCheckout = {
      loja_id: store.api_store_id,
      produtos: productsFinish,
      voucher: voucher.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, ""),
      metodo_pagamento: typePayment,
      cliente_id: toten.client_id,
      cpf: cpf.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, ""),
    };

    api
      .post(
        `api/self-checkout/${store.api_store_id}/${store.api_domain_id}/${toten.id}/save-purchase`,
        selfCheckout
      )
      .then((resp) => {
        setResetTimeOperation(true);
        setLoading(false);
        if (typePayment === "PIX") {
          setSavePurchasePixPayment(resp.data.data.savePurchase);
        } else {
          setSavePurchaseRequest(resp.data.data.savePurchase);
        }
      })
      .catch((error) => {
        setLoading(false);
        verifyError(error);
      });
  };

  const removeVoucher = () => {
    setVoucher("");
    setChangedVoucher(true);
    let voucher = document.getElementById("ticket");
    voucher.value = "";
  };

  const finishPayment = () => {
    setWantsToFinish(true);
    if (!cpfValidate) showInputCPF();
    else openAreaSelectPayment();
  };

  const openAreaSelectPayment = () => {
    const modelSelectCard = document.getElementById("modal-select-card");
    modelSelectCard.style.display = "flex";
  };

  const confirmPayment = (typePayment) => {
    savePurchase(typePayment);
    toggleModalLoadingPayment("open");
  };

  const newTryVerify = () => {
    setShouldIntervalBeCancelled(false);
    let myIntervalID = setInterval(verifyPayment, 3000);
    setIntervalID(myIntervalID);
    setTimeout(function () {
      setShouldIntervalBeCancelled(true);
    }, 30000);
  };

  const successfulPayment = () => {
    setShouldIntervalBeCancelled(true);
    history.push(`/${screenRotation}/panel-toten/finish-payment`);
  };

  const verifyPayment = () => {
    let pedidoId =
      Object.keys(savePurchaseRequest).length !== 0
        ? savePurchaseRequest?.pedido_id
        : savePurchasePixPayment.pedido_id;

    if (countTime < countTimeLimit) {
      api
        .get(`api/self-checkout/${pedidoId}/verify-payment-purchase`)
        .then((resp) => {
          if (resp.data.data.successfulPayment) successfulPayment();
        })
        .catch((error) => {
          verifyError(error);
        });
    } else {
      setShouldIntervalBeCancelled(true);
      toggleBtnsModalLoadingPayment("open");
    }
  };

  const cancelPurchase = () => {
    history.push(`/${screenRotation}/panel-toten`);
  };

  const toggleBtnsModalLoadingPayment = (toggle) => {
    let areaBtnsModalLoadingPayment = document.getElementById(
      "btns-modal-loading-payment"
    );
    if (toggle === "close") {
      areaBtnsModalLoadingPayment.style.display = "none";
    }
    if (toggle === "open") {
      areaBtnsModalLoadingPayment.style.display = "flex";
    }
  };

  const toggleModalLoadingPayment = (toggle) => {
    let modalLoadingPayment = document.getElementById(
      "container-modal-loading-payment"
    );
    if (toggle === "close")
      modalLoadingPayment.style.transform = "translateX(5000vw)";
    if (toggle === "open")
      modalLoadingPayment.style.transform = "translateX(0vw)";
  };

  const toggleIconCodeBar = (toggle) => {
    let areaIconCodeBar = document.getElementById("area-read-bar-code");
    let msgScanListProducts = document.getElementById("msg-scan-list-product");
    let listProducts = document.getElementById("list-products");
    let areaFormCheckout = document.getElementById(`area-form-checkout-${screenRotation}`);
    if (toggle === "close") {
      areaIconCodeBar.style.display = "none";
      listProducts.style.display = "flex";
      areaFormCheckout.style.display = "flex";
      if(msgScanListProducts) msgScanListProducts.style.display = "block";
    }
    if (toggle === "open") {
      areaIconCodeBar.style.display = "flex";
      listProducts.style.display = "none";
      areaFormCheckout.style.display = "none";
      if(msgScanListProducts) msgScanListProducts.style.display = "none";
    }
  };

  const verifyVoucher = (resumePurchase) => {
    if (
      resumePurchase?.settings?.voucher?.active === false &&
      changedVoucher === true &&
      voucher != ""
    ) {
      showNotify(
        "error",
        resumePurchase?.settings?.voucher?.message || "Voucher inválido!"
      );
      setVoucher("");
      setChangedVoucher(false);
    }
    setValidateVoucher(resumePurchase?.settings?.voucher?.active);
  };

  function StatusVerifyVoucher() {
    if (loading && changedVoucher) {
      return <AiOutlineLoading3Quarters className="icon-loading" />;
    }
    if (validateVoucher) {
      return <GiConfirmed size="30" />;
    } else {
      return <HiChevronRight size="30" />;
    }
  }

  function VerifyPixPayment() {
    if (Object.keys(savePurchasePixPayment).length !== 0) {
      return (
        <>
          <div className="area-img-qrcode">
            <img src={savePurchasePixPayment?.qrcode?.base64} />
          </div>
        </>
      );
    } else {
      return "";
    }
  }

  useEffect(() => {
    let inputProductCode = document.getElementById("search-product-code");

    document.addEventListener("keypress", (event) => {
      if (event.key === "Enter") {
        selectProduct();
      } else {
        inputProductCode.focus();
      }
    });

    document.addEventListener("keydown", (event) => {
      if (event.key === "Backspace") {
        inputProductCode.focus();
      }
    });

    document.addEventListener("click", (event) => {
      setResetTimeOperation(true);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      Object.keys(savePurchaseRequest).length !== 0 ||
      Object.keys(savePurchasePixPayment).length !== 0
    ) {
      setShouldIntervalBeCancelled(false);
      let myIntervalID = setInterval(verifyPayment, 3000);
      setIntervalID(myIntervalID);
      setTimeout((_) => {
        successfulPayment();
      }, 30000);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savePurchasePixPayment, savePurchaseRequest]);

  useEffect(() => {
    if (shouldIntervalBeCancelled) {
      clearInterval(intervalID);
    }
  }, [shouldIntervalBeCancelled]);

  useEffect(() => {
    if (changedVoucher) resumePurchase();
  }, [voucher]);

  useEffect(() => {
    resumePurchase();

    if (products.length !== 0) {
      setProducts(products);
      toggleIconCodeBar("close");
    }
    if (products.length === 0) {
      toggleIconCodeBar("open");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products, validateVoucher]);

  return {
    shouldIntervalBeCancelled,
    VerifyPixPayment,
    countLoop,
    cancelPurchase,
    newTryVerify,
    selectProduct,
    products,
    removeProduct,
    addProduct,
    decreaseProduct,
    handleSubmit,
    changeCode,
    finishPayment,
    setCpf,
    setCpfValidate,
    closeInputCpf,
    setVoucher,
    changedVoucher,
    setChangedVoucher,
    setFilledVoucher,
    verifyVoucher,
    resumePurchase,
    showInputCPF,
    cpfValidate,
    showInputTicket,
    StatusVerifyVoucher,
    resumePurchaseRequest,
    priceTotal,
    voucher,
    removeVoucher,
    confirmPayment,
    resetTimeOperation,
    setResetTimeOperation
  };
};

export default CartFactory;

import React, {useState, useEffect} from 'react';
import apiService from '../../../../services/api.js';
import Loading from '../../../../components/Loading/index.jsx';
import { Can } from "react-access-level";
import { verifyError } from '../../../../services/actionsAppService.jsx';

const Overview = () => {
	const api = apiService.get();
	const [storesData, setStoresData] = useState([]);
	const [totensData, setTotensData] = useState([]);
	const [usersData, setUsersData] = useState([]);
	const [purchasesData, setPurchasesData] = useState([]);
	const [loading, setLoading] = useState(false);


	const dashboardData = () => {
		setLoading(true);
	    api.get(`api/dashboard/all-data`)
		.then(resp => {
			setStoresData(resp.data.data.storesData);
			setTotensData(resp.data.data.totensData);
			setUsersData(resp.data.data.usersData);
			setPurchasesData(resp.data.data.purchasesData);
			console.log(resp.data.data.storesData.count);
			setLoading(false);
		})
		.catch(error =>{
			setLoading(false);
			verifyError(error);
		})
	}

	
	useEffect(() => {
		dashboardData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<>
			<div className="area-page-dashboard">

				<Can 
					resource="dashboard"
					authority="dashboard-view"
					otherwiseComponent={<label>Sem acesso!</label>}
				>
					<h2 className="title-endpoint">Dashboard</h2>

					{ (loading) ? (<Loading />) : (
						<>
							<div className="row">
							    <div className="col box-number">
							    	<span>{storesData?.count}</span>
							    	<label>Lojas</label>
							    </div>
							    <div className="col box-number">
							    	<span>{totensData?.count}</span>
							    	<label>Totens</label>
							    </div>
						   		<div className="col box-number">
						    		<span>{usersData?.count}</span>
							    	<label>Usuários</label>
						    	</div>
						    	<div className="col box-number">
						    		<span>{purchasesData?.count}</span>
							    	<label>Vendas</label>
						    	</div>
							</div>

							<div className="row">
							    <div className="col box-list">
							    	<ul>
							    		<li>teste</li>
							    		<li>teste</li>
							    		<li>teste</li>
							    		<li>teste</li>
							    		<li>teste</li>
							    	</ul>
							    </div>
							    <div className="col box-list">
							    	<ul>
							    		<li>teste</li>
							    		<li>teste</li>
							    		<li>teste</li>
							    		<li>teste</li>
							    		<li>teste</li>
							    	</ul>
							    </div>
						   		<div className="col box-list">
						    		<ul>
							    		<li>teste</li>
							    		<li>teste</li>
							    		<li>teste</li>
							    		<li>teste</li>
							    		<li>teste</li>
							    	</ul>
						    	</div>
							</div>
						</>
					)}
				</Can>

			</div>
		</>
	);
};

export default Overview;
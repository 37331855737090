import React, {useState, useEffect} from 'react';
import HeaderStore from '../Header/index.jsx';
import { Table } from 'react-bootstrap';
import apiService from '../../../../services/api.js';
import Loading from '../../../../components/Loading/index.jsx';
import { Can } from "react-access-level";
import { verifyError } from '../../../../services/actionsAppService.jsx';

const ViewStore = ({ match, location }) => {
	const api = apiService.get();
	const [store, setStore] = useState([]);
	const [loading, setLoading] = useState(false);

	const {
	    params: { id }
	} = match;

	const viewStore = () => {
		setLoading(true);
	    api.get(`api/store/${id}`)
		.then(resp => {
			setStore(resp.data.data.store);
			setLoading(false);
		})
		.catch(error =>{
			setLoading(false);
			verifyError(error);
		})
	}

	
	useEffect(() => {
		viewStore()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	

	return (
		<>
			<div className="area-crud">
				<HeaderStore></HeaderStore>

				<Can 
					resource="store"
					authority="store-create"
					otherwiseComponent={<label>Sem acesso!</label>}
				>
					<h5 className="mb-3">Dados da loja</h5>

					{ (loading) ? (<Loading />) : (
						<Table striped bordered hover>
							<thead>
								<tr>
									<th>Coluna</th>
									<th>Valor</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Id</td>
									<td>{store?.id}</td>
								</tr>
								<tr>
									<td>Nome da loja</td>
									<td>{store?.name}</td>
								</tr>
								<tr>
									<td>CNPJ</td>
									<td>{store?.cnpj}</td>
								</tr>
								<tr>
									<td>Usuário</td>
									<td>{store?.user?.name}</td>
								</tr>
								<tr>
									<td>Estado</td>
									<td>{store?.address?.state}</td>
								</tr>
								<tr>
									<td>Cidade</td>
									<td>{store?.address?.city}</td>
								</tr>
								<tr>
									<td>CEP</td>
									<td>{store?.address?.zipcode}</td>
								</tr>
								<tr>
									<td>Bairro</td>
									<td>{store?.address?.neighborhood}</td>
								</tr>
								<tr>
									<td>Endereço</td>
									<td>{store?.address?.street}</td>
								</tr>
								<tr>
									<td>Nº</td>
									<td>{store?.address?.number}</td>
								</tr>
								<tr>
									<td>ID API Loja</td>
									<td>{store?.api_store_id}</td>
								</tr>
								<tr>
									<td>ID Domínio</td>
									<td>{store?.api_domain_id}</td>
								</tr>
							</tbody>
						</Table>
					)}
				</Can>
			</div>
		</>
	);
};

export default ViewStore;
import React, {useState} from 'react';
import { BiSearch } from "react-icons/bi";
import { Link } from 'react-router-dom';
import { InputGroup, FormControl, Form, Button } from 'react-bootstrap';
import { Can } from "react-access-level";
import { useHistory } from "react-router-dom";

const HeaderStore = () => {
    const history = useHistory();
    const [column, setColumn] = useState('name');
    const [value, setValue] = useState('');

    const changeColumn = (ev) => {
        setColumn(ev.target.value);
    }

    const changeValue = (ev) => {
        setValue(ev.target.value.trim());
    }

    const onSubmit = async (event) => {
        event.preventDefault();
        if(value === '')
            history.push(`/dashboard/store/list`);
        else
            history.push(`/dashboard/store/search/${column}/${value}`);
    }

	return (
		<>
			<h2 className="title-endpoint">Lojas</h2>

            <div className="row area-actions-crud">
                <div className="nav-crud">
                    <Can resource="store" authority="store-create">
                	   <Link to='/dashboard/store/register' className="btn btn-dark">Adicionar</Link>
                    </Can>
                    <Can resource="store" authority="store-view">
                        <Link to='/dashboard/store/list' className="btn btn-dark">Listar</Link>
                    </Can>
                </div>
                
                <Can resource="store" authority="store-view">
                    <Form onSubmit={onSubmit}>
                        <select className="form-select" name="column" onChange={(ev) => changeColumn(ev)}>
                            <option value="name">Nome</option>
                        </select>

                        <InputGroup className="mb-3 input-search">
                            <FormControl placeholder="Busque uma loja" onChange={(ev) => changeValue(ev)} />
                            <Button className="btn btn-dark" type="submit"><BiSearch /></Button>
                        </InputGroup>
    	            </Form>
                </Can>
            </div>
        </>
	);
};

export default HeaderStore;
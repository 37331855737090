import React from 'react';
import { useState } from 'react';
import { Table} from 'react-bootstrap';
import Loading from '../../../../components/Loading/index.jsx';
import Pagination from '../../../../components/Pagination/index.jsx';
import { BiShowAlt, BiHide } from "react-icons/bi";

const ListLogsWebhook = ({
  logsWebhook,
  loading,
  offset,
  setOffset,
  qtdPages
}) => {
  if (loading) return <Loading />

  const data = logsWebhook.map(row => ({...JSON.parse(row.value), ...row}))

	return (
		<>
      {logsWebhook?.length === 0
        ? <label>Nenhum registro encontrado...</label>
        : <div>
            <Table striped bordered hover>
            <thead>
              <tr>
                <th>ID Packk</th>
                <th>ID Loja</th>
                <th>Status</th>
                <th>Valor (R$)</th>
                <th>Data</th>
                <th>Dados bruto</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((log) => <Row log={log}/>)}
            </tbody>
          </Table>
          <Pagination
            qtdPages={qtdPages}
            offset={offset}
            setOffset={setOffset}
          />
        </div>
      }
		</>
	);
};

const Row = ({log}) => {
  const [showAll, setShowAll] = useState(false)

  const formatDateTime = (dateTime) => {
		let t = dateTime.split(/[-T:.]/);
		let d = `${t[2]}/${t[1]}/${t[0]} ${t[3]}:${t[4]}:${t[5]}`;
		return d;
	}

  const value = showAll 
    ? log.value
    : `${log.value.substring(0, 50)}...`

  const linkToggleShowAll = (
    <a href='#'
      onClick={() => setShowAll(!showAll)}
      className="btn btn-dark">
      {showAll
        ? <BiHide />
        : <BiShowAlt />
      }
    </a>
  )
  
  return (
    <tr key={log.id}>
      <th>{log.data.id}</th>
      <td>{log.data.store_id}</td>
      <td>{log.data.status}</td>
      <td>{log.data.amount/100.00}</td>
      <td>{formatDateTime(log.created_at)}</td>
      <td style={{maxWidth: 300, overflowWrap: "break-word"}}>
      {linkToggleShowAll}{"   "}{value}
      </td>
    </tr>
  )
}

export default ListLogsWebhook;
import React from 'react';
import './style.css';

const ActionButton = props => {
	const initApp = () => {}

	return (
		<>
			<button type={props.type} className="btn-zaitt" style={{background: props.background }} onClick={initApp}>{props.text}</button>
		</>
	);
};

export default ActionButton;
import React from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import "./style.css";

const ModalLoadingPayment = ({
  shouldIntervalBeCancelled,
  countLoop,
  cancelPurchase,
  newTryVerify,
  VerifyPixPayment,
  priceTotal,
}) => {
  const renderButtons =
    countLoop == 1 ? (
      <div
        className="btns-modal-loading-payment"
        id="btns-modal-loading-payment"
      >
        <button className="btn btn-outline-danger" onClick={cancelPurchase}>
          Cancelar compra
        </button>
        <button className="btn btn-success" onClick={newTryVerify}>
          Tentar novamente
        </button>
      </div>
    ) : (
      <></>
    );

  if (shouldIntervalBeCancelled)
    return (
      <div
        className="container-modal-loading-payment"
        id="container-modal-loading-payment"
      >
        <div className="modal-loading-payment" id="modal-loading-payment">
          <label>FALHA NO PAGAMENTO</label>
          <VerifyPixPayment />
          {renderButtons}
        </div>
      </div>
    );

  return (
    <div
      className="container-modal-loading-payment"
      id="container-modal-loading-payment"
    >
      <div className="modal-loading-payment" id="modal-loading-payment">
        <label>AGUARDANDO PAGAMENTO</label>

        <VerifyPixPayment />

        <span>
          <AiOutlineLoading3Quarters size="60" />
        </span>

        <div className="area-transference-pix" id="area-transference-pix">
          <label>
            Para realizar a transferencia via PIX, abra o<br />
            seu aplicativo de pagamentos e aponte seu
            <br />
            celular para o QR Code que está na tela.
          </label>
        </div>

        <div className="area-read-card" id="area-read-card">
          <label>
            Agora por favor clique no botão "Enter" da maquineta
            <br />
            para buscar pelo valor da compra, {priceTotal}, e em
            <br />
            seguida realizar o pagamento.
          </label>
        </div>
      </div>
    </div>
  );
};

export default ModalLoadingPayment;

import React, {useState, useEffect} from 'react';
import HeaderStore from '../Header/index.jsx';
import apiService from '../../../../services/api.js';
import List from './List'
import { Can } from 'react-access-level';
import { showNotify, verifyError } from '../../../../services/actionsAppService.jsx';

const ListLogsWebhook = () => {
	const [logsWebhook, setLogsWebhook] = useState([]);
	const [loading, setLoading] = useState(false);
	const [offset, setOffset] = useState(1);
	const [qtdPages, setQtdPages] = useState(1);
	const api = apiService.get();

	const listLogsWebhook = () => {
		setLoading(true);
		api.get(`api/purchase/logs-webhook?page=${offset}`)
			.then(resp => {
			setLogsWebhook(resp.data.data.logsWebhook);
			setQtdPages(resp.data.data.pagination.pages);
			setLoading(false);
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			setLoading(false);
			verifyError(error);
		})
	}

	useEffect(() => {
		listLogsWebhook();
	}, [offset])


	return (
		<>
			<div className="area-crud">

				<HeaderStore/>

				<Can 
					resource="purchase"
					authority="purchase-view"
					otherwiseComponent={<label>Sem acesso!</label>}
				>
					<h5 className="mb-3">Lista de logs do webhook</h5>
					<List
						{...{
							logsWebhook,
							loading,
							offset,
							setOffset,
							qtdPages
						}}
					/>
				</Can>
			</div>
		</>
	);
};

export default ListLogsWebhook;
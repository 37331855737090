import React, {useState, useEffect} from 'react';
import HeaderToten from '../Header/index.jsx';
import { Button, Form, Row, Col } from 'react-bootstrap';
import apiService from '../../../../services/api.js';
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import Loading from '../../../../components/Loading/index.jsx';
import { Can } from "react-access-level";
import { toast } from 'react-toastify';
import PanelPermissions from '../../../../components/PanelPermissions/index.jsx';
import { showNotify, verifyError } from '../../../../services/actionsAppService.jsx';

const EditRole = ({ match, location }) => {
	const {
	    params: { id }
	} = match;
	const api = apiService.get();
	const history = useHistory();
	const [permissions, setPermissions] = useState([]);
	const [allPermissions, setAllPermissions] = useState([]);
	const [errors, setErrors] = useState('');
	const [loading, setLoading] = useState(false);
	const { register, handleSubmit, setValue, getValues } = useForm();

	const initRole = () => {
		setLoading(true);
		api.get(`api/role/${id}`)
		.then(resp => {
			const data = resp.data.data.role;
			setValue("name", data.name);
			setValue("slug", data.slug);

			let permissionsTemp = [];
			data.permissions.forEach(function(item){
				permissionsTemp.push(item.name);
            })
            setPermissions(permissionsTemp);
            setValue("permissions", permissionsTemp)
			setLoading(false);
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			setErrors(error.message)
			verifyError(error);
		})
	}

	const initAllPermissions = () => {
		api.get('api/permission/all')
		.then(resp => {
			setAllPermissions(resp.data.data.permissions);
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			setErrors(error?.response?.data?.data?.errors)
			verifyError(error);
		});
	}

	const onUpdate = data => {
		setLoading(true);

		data.permissions = [];
		permissions.forEach(function(item){
			data.permissions.push({
				'name': item,
				'slug': item.replace(/\s/g , "-"),
			});
		});

		console.log(data);

		api.put(`api/role/${id}`, data)
		.then(resp => {
			showNotify('success', 'Função atualizada com sucesso!');
			setLoading(false);
			history.push('/dashboard/access-control/list');
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			setErrors(error?.response?.data?.data?.errors)
			setLoading(false);
			verifyError(error);
		})
	}

	const selectedPermissions = permission => {
		setPermissions(permission);
	};


	useEffect(() => {
		setValue("permissions", permissions)
		if(getValues("permissions")?.length === 0){
			initAllPermissions();
			initRole();
			
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);


	return (
		<>
			<div className="area-crud">
				<HeaderToten></HeaderToten>

				<Can 
					resource="acl"
					authority="acl-update"
					otherwiseComponent={<label>Sem acesso!</label>}
				>
					<h5 className="mb-3">Formulário de edição</h5>

					{ (loading) ? (<Loading />) : (null)}

					<Form onSubmit={handleSubmit(onUpdate)} className="form-register">
						<Row className="mb-3">
							<Col sm="6">
								<Form.Group>
								    <label htmlFor="name" className="form-label">Role:</label>
								    <input type="text" name="name" className="form-control" id="name" {...register("name")} />
								    <span className="error-message">{errors?.name ? errors.name[0] : null}</span>
								</Form.Group>
							</Col>

							<Col sm="6">
								<Form.Group>
								    <label htmlFor="slug" className="form-label">Slug:</label>
								    <input type="text" name="slug" className="form-control" id="slug" {...register("slug")} />
								    <span className="error-message">{errors?.slug ? errors.slug[0] : null}</span>
								</Form.Group>
							</Col>
						</Row>

						<Row className="mb-3">
							<Col sm="12">
								<Form.Group>
								    <label htmlFor="permissions" className="form-label">Permissões:</label>
								    <PanelPermissions
								    selectedPermissions={selectedPermissions}
								    initValues={getValues('permissions')}
								    allPermissions={allPermissions}
								    placeholder="permissão" />
								    <span className="error-message">{errors?.permissions ? errors.permissions[0] : null}</span>
								</Form.Group>
							</Col>
						</Row>

					    <Row className="mb-3">
					    	<Col>
					    		<Button type="submit" className="btn">Salvar</Button>
					    	</Col>
					    </Row>
					    
					</Form>
				</Can>
			</div>
		</>
	);
};

export default EditRole;
import React from 'react';
//import { BiSearch } from "react-icons/bi";
import { Link } from 'react-router-dom';
//import { InputGroup, FormControl, Form, Button } from 'react-bootstrap';
import { Can } from "react-access-level";
import { useHistory } from "react-router-dom";

const HeaderPurchase = () => {
    const history = useHistory();
    //const [column, setColumn] = useState('name');
    //const [value, setValue] = useState('');

    /*const changeColumn = (ev) => {
        setColumn(ev.target.value);
    }

    const changeValue = (ev) => {
        setValue(ev.target.value.trim());
    }

    const onSubmit = async (event) => {
        event.preventDefault();
        if(value === '')
            history.push(`/dashboard/purchase/list`);
        else
            history.push(`/dashboard/purchase/search/${column}/${value}`);
    }*/

	return (
		<>
			<h2 className="title-endpoint">Compras</h2>

            <div className="row area-actions-crud">
                <div className="nav-crud">
                	{/*
                    <Can resource="purchase" authority="purchase-create">
                	   <Link to='/dashboard/purchase/register' className="btn btn-dark">Adicionar</Link>
                    </Can>
                	*/}
                    <Can resource="purchase" authority="purchase-view">
                        <Link to='/dashboard/purchase/list' className="btn btn-dark">Listar</Link>
                    </Can>
                    <Can resource="purchase" authority="purchase-view">
                        <Link to='/dashboard/purchase/logs-webhook/list' className="btn btn-dark">Logs do webhook</Link>
                    </Can>
                </div>
                {/*
                <Can resource="purchase" authority="purchase-view">
                    <Form onSubmit={onSubmit}>
                        <select className="form-select" name="column" onChange={(ev) => changeColumn(ev)}>
                            <option value="name">Nome</option>
                        </select>

                        <InputGroup className="mb-3 input-search">
                            <FormControl placeholder="Busque uma compra" onChange={(ev) => changeValue(ev)} />
                            <Button className="btn btn-dark" type="submit"><BiSearch /></Button>
                        </InputGroup>
    	            </Form>
                </Can>
            	*/}
            </div>
        </>
	);
};

export default HeaderPurchase;
import React from 'react';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { BiShowAlt } from "react-icons/bi";
import Loading from '../../../../components/Loading/index.jsx';
import Pagination from '../../../../components/Pagination/index.jsx';
import { Can } from "react-access-level";
import { formatCpf } from '../../../../helpers/formaters.js';

const ListPurchase = ({
  purchases,
  loading,
  offset,
  setOffset,
  qtdPages
}) => {
	const formatDateTime = (dateTime) => {
		let t = dateTime.split(/[-T:.]/);
		let d = `${t[2]}/${t[1]}/${t[0]} ${t[3]}:${t[4]}:${t[5]}`;
		return d;
  }

  if (loading) return <Loading />
  if (purchases?.length === 0) return <label>Nenhum registro encontrado...</label>
	
  return (
    <div>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>CPF do comprador</th>
            <th>Total (R$)</th>
            <th>Toten</th>
            <th>Status</th>
            <th>Id da compra na Packk</th>
            <th>Data</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {purchases?.map((purchase) => (
            <tr key={purchase.id}>
              <td>{formatCpf(purchase.cpf)}</td>
              <td>{purchase.total/100.00}</td>
              <td>{purchase.toten.name}</td>
              <td>{purchase.status}</td>
              <td>{purchase.purchase_id}</td>
              <td>{formatDateTime(purchase.created_at)}</td>
              <td>
                <Can resource="purchase" authority="purchase-view">
                  <Link to={`/dashboard/purchase/view/${purchase.id}`} className="btn btn-dark">
                    <BiShowAlt />
                  </Link>
                </Can>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Pagination
        qtdPages={qtdPages}
        offset={offset}
        setOffset={setOffset}
      />
    </div>
	);
};

export default ListPurchase;
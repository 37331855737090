import React, {useState, useEffect} from 'react';
import { verifyError } from '../../../services/actionsAppService.jsx';
import IconCodeBar from '../../../assets/images/codigo-de-barra.png';
import './style.css';

const ReadBarCode = (props) => {
	return (
		<div className="area-read-bar-code" id="area-read-bar-code">
			<p className="msg-scan">Passe o código de barras dos seus<br />produtos no scan</p>
			<img src={IconCodeBar} />
		</div>
	);
};

export default ReadBarCode;
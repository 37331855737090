import React from 'react';
import './style.css';
import { Dropdown, Button } from 'react-bootstrap';
import { useHistory, Link } from "react-router-dom";

const Header = () => {
	const history = useHistory();
	let user = JSON.parse(localStorage.getItem('dataUser'));

	const logout = () => {
		localStorage.clear();
		window.location.reload();
	}

	const pageProfile = () => {
		history.push(`dashboard/profile/view`);
	}

	const pagePanelToten = () => {
		window.open(`/panel-toten/login` , '_blank');
	}

	return (
		<>
			<header>
				<nav>
					<label className="welcome">Bem vindo: {user.name}</label>

					<Link onClick={pagePanelToten} className="btn btn-primary">Painel toten</Link>

					<Dropdown className="dropdown-nav">
						<Dropdown.Toggle id="dropdown-basic">
						    Zaitt
						</Dropdown.Toggle>

						<Dropdown.Menu>
						    <Dropdown.Item onClick={pageProfile}>Perfil</Dropdown.Item>
						  	<Dropdown.Item onClick={logout}>Sair</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				</nav>
			</header>
		</>
	);
};

export default Header;
import React, { useEffect } from "react";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const PanelToten = () => {
  const { width, height } = getWindowDimensions();

  useEffect(() => {
    window.location.href = `/${getScreenType()}/panel-toten`;
  }, []);

  const getScreenType = () => {
    if (width < height) return "portrait";
    else return "landscape";
  };

  return <div>...Carregando</div>;
};

export default PanelToten;

import React, { useEffect } from "react";
import "./style.css";
import logo2 from "../../../../assets/images/logo-2.png";
import Footer from "../../../../components/PanelToten/Footer/index.jsx";
import imageInfoVoucher from "../../../../assets/images/retirar_comprovante.png";

const FinishPayment = () => {

  const locationInitApp = () => {
    setTimeout(function () {
      window.location = "/portrait/panel-toten/";
    }, 20000);
  };

  useEffect(() => {
    locationInitApp();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="wrapper-portrait">
      <div className="page-finish-payment-portrait">
        <img src={logo2} className="logo-portrait" />

        <h1 className="title-finish">PRONTO</h1>
        <p className="msg-purchase-successfully">
          Compra realizada com sucesso!
        </p>
        <label className="msg-obs">Agora, lembre-se de:</label>

        <img className="image-info-voucher" src={imageInfoVoucher} />

        <h1 className="msg-thanks">
          Volte sempre
          <br />
          <span>a Zaitt é sua!</span>
        </h1>
      </div>
      <Footer />
    </div>
  );
};

export default FinishPayment;

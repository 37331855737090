import React, {useState, useEffect} from 'react';
import HeaderToten from '../Header/index.jsx';
import { Button, Form, Row, Col } from 'react-bootstrap';
import apiService from '../../../../services/api.js';
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import Loading from '../../../../components/Loading/index.jsx';
import { Can } from "react-access-level";
import { showNotify, verifyError } from '../../../../services/actionsAppService.jsx';

const EditToten = ({ match, location }) => {
	const {
	    params: { id }
	} = match;
	const api = apiService.get()
	const history = useHistory();
	const [stores, setStores] = useState([]);
	const [errors, setErrors] = useState('');
	const [loading, setLoading] = useState(false);

	const { register, handleSubmit, setValue, getValues } = useForm();

	const initToten = () => {
		setLoading(true);
		api.get(`api/toten/${id}`)
		.then(resp => {
			const data = resp.data.data.toten;
			setValue("name", data.name);
			setValue("status", data.status);
			setValue("store_id", data.store_id);
			setValue("email", data.email);
			setValue("password", data.password);
			setValue("client_id", data.client_id);
			setValue("token", data.token);
			setLoading(false);
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			setErrors(error.message)
			verifyError(error);
		})
	}

	const onUpdate = data => {
		setLoading(true);

		api.put(`api/toten/${id}`, data)
		.then(resp => {
			showNotify('success', 'Toten atualizado com sucesso!');
			setLoading(false);
			history.push('/dashboard/toten/list');
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			setErrors(error?.response?.data?.data?.errors)
			setLoading(false);
			verifyError(error);
		})
	}

	const initStores = () => {
		api.get('api/store/all')
		.then(resp => {
			const data = resp.data.data.stores;
			setStores(data)
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			setErrors(error.message)
			verifyError(error);
		})
	}


	useEffect(() => {
		initToten();
		initStores();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);


	return (
		<>
			<div className="area-crud">
				<HeaderToten></HeaderToten>

				<Can 
					resource="toten"
					authority="toten-update"
					otherwiseComponent={<label>Sem acesso!</label>}
				>
					<h5 className="mb-3">Formulário de edição</h5>

					{ (loading) ? (<Loading />) : (null)}

					<Form onSubmit={handleSubmit(onUpdate)} className="form-register">
						<Row className="mb-3">
							<Col sm="4">
								<Form.Group>
								    <label htmlFor="name" className="form-label">Nome:</label>
								    <input type="text" name="name" className="form-control" id="name" {...register("name")} />
								    <span className="error-message">{errors?.name ? errors.name[0] : null}</span>
								</Form.Group>
							</Col>

							<Col sm="4">
								<Form.Group>
								    <label htmlFor="status" className="form-label">Status:</label>
								    <select className="form-select" name="status" {...register("status")}>
								    	<option value="funcionando">Funcionando</option>
								    	<option value="manutenção">Manutenção</option>
								    	<option value="testando">Testando</option>
							        </select>
								    <span className="error-message">{errors?.status ? errors.status[0] : null}</span>
								</Form.Group>
							</Col>

							<Col sm="4">
								<Form.Group>
								    <label className="form-label">Loja:</label>
								    <select className="form-select" name="store_id" {...register("store_id")} >
								    	<option value="">Selecionar</option>
								    	{stores?.map((store, index) => (
											<option selected={store.id === getValues("store_id")} value={store.id} key={index}>{store.name}</option>
								    	))}
							        </select>
								    
								    <span className="error-message">{errors?.store_id ? errors.store_id[0] : null}</span>
								</Form.Group>
							</Col>
						</Row>

						<Row className="mb-3">
							<Col sm="4">
								<Form.Group>
								    <label htmlFor="email" className="form-label">Email:</label>
								    <input type="text" name="email" className="form-control" id="email" {...register("email")} />
								    <span className="error-message">{errors?.email ? errors.email[0] : null}</span>
								</Form.Group>
							</Col>

							<Col sm="4">
								<Form.Group>
								    <label htmlFor="password" className="form-label">Senha:</label>
								    <input type="password" name="password" className="form-control" id="password" {...register("password")} />
								    <span className="error-message">{errors?.password ? errors.password[0] : null}</span>
								</Form.Group>
							</Col>

							<Col sm="4">
								<Form.Group>
								    <label htmlFor="client_id" className="form-label">Id do cliente:</label>
								    <input type="text" name="client_id" className="form-control" id="client_id" {...register("client_id")} />
								    <span className="error-message">{errors?.client_id ? errors.client_id[0] : null}</span>
								</Form.Group>
							</Col>
						</Row>

						<Row className="mb-3">
							<Col sm="12">
								<Form.Group>
								    <label htmlFor="token" className="form-label">Token:</label>
								    <textarea name="token" className="form-control" rows="8" id="token" {...register("token")}></textarea>
								    <span className="error-message">{errors?.token ? errors.token[0] : null}</span>
								</Form.Group>
							</Col>
						</Row>

					    <Row className="mb-3">
					    	<Col>
					    		<Button type="submit" className="btn">Salvar</Button>
					    	</Col>
					    </Row>
					    
					</Form>
				</Can>
			</div>
		</>
	);
};

export default EditToten;
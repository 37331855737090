import React, {useState, useEffect} from 'react';
import HeaderStore from '../Header/index.jsx';
import apiService from '../../../../services/api.js';
import { Can } from "react-access-level";
import { showNotify, verifyError } from '../../../../services/actionsAppService.jsx';
import List from './List.jsx';

const ListPurchase = (/*{ match, location }*/) => {
	const [purchases, setPurchases] = useState([]);
	const [loading, setLoading] = useState(false);
	const [offset, setOffset] = useState(1);
	const [qtdPages, setQtdPages] = useState(1);
	const api = apiService.get()

	const listPurchase = () => {
		setLoading(true);
	
		api.get(`api/purchase?page=${offset}`)
		.then(resp => {
			setPurchases(resp.data.data.purchases);
			setQtdPages(resp.data.data.pagination.pages);
			setLoading(false);
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			setLoading(false);
			verifyError(error);
		})
	}

	useEffect(() => {
		listPurchase();
	}, [offset])


	return (
		<>
			<div className="area-crud">

				<HeaderStore/>

				<Can 
					resource="purchase"
					authority="purchase-view"
					otherwiseComponent={<label>Sem acesso!</label>}
				>
					<h5 className="mb-3">Lista de compras</h5>

					<List
						{...{
							purchases,
							loading,
							offset,
							setOffset,
							qtdPages
						}}
					/>
				</Can>
			</div>
		</>
	);
};

export default ListPurchase;
import React from 'react';
import { Table } from 'react-bootstrap';
import Loading from '../../../../components/Loading/index.jsx';
import { formatCpf } from '../../../../helpers/formaters.js';

const PurchaseData = ({ loading, purchase }) => {
  if (loading) return <Loading />
	return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Coluna</th>
          <th>Valor</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Id</td>
          <td>{purchase?.id}</td>
        </tr>
        <tr>
          <td>CPF</td>
          <td>{formatCpf(purchase?.cpf)}</td>
        </tr>
        <tr>
          <td>Quantidade de produtos</td>
          <td>{purchase?.quantity}</td>
        </tr>
        <tr>
          <td>Total</td>
          <td>{purchase?.total/100.00}</td>
        </tr>
        <tr>
          <td>Hora que a porta abriu</td>
          <td>{purchase?.hour_door_open}</td>
        </tr>
        <tr>
          <td>Toten</td>
          <td>{purchase?.toten_id}</td>
        </tr>
        <tr>
          <td>Status</td>
          <td>{purchase?.status}</td>
        </tr>
        <tr>
          <td>Id da compra (API)</td>
          <td>{purchase?.purchase_id}</td>
        </tr>
      </tbody>
    </Table>
	);
};

export default PurchaseData;
import React from 'react';
import './style.css';
import { FaFacebookF, FaInstagram } from "react-icons/fa";
import IconeOpeningTime from "../../../assets/images/icone-1.png";

const Footer = () => {

	return (
		<footer>
			<div className="social-networks">
				<span><FaFacebookF /></span>
				<span><FaInstagram /></span>
				<label>@zaitt_br</label>
			</div>

			<div className="opening-time">
				<img src={IconeOpeningTime} />
				<label>Aberto 24h</label>
			</div>
		</footer>
	);
};

export default Footer;
import React from "react";
import "./style.css";
import { Link } from "react-router-dom";
import logo2 from "../../../../assets/images/logo-2.png";
import Footer from "../../../../components/PanelToten/Footer/index.jsx";
import ActionButton from "../../../../components/PanelToten/ActionButton/index.jsx";

const HomeToten = () => {
  return (
    <div className="wrapper-portrait">
      <div className="page-home-portrait">
        <img className="logo-portrait" src={logo2} />

        <h1 className="msg-welcome-portrait">BEM-VINDO À ZAITT</h1>
        <h2 className="msg-first-smart-store-portrait">
          A PRIMEIRA SMART STORE DA AMÉRICA LATINA.
        </h2>

        <Link className="btn-init-portrait" to="/portrait/panel-toten/shopping-cart">
          <ActionButton type="button" text="COMEÇAR >>" background="#dce169" />
        </Link>

        <h3 className="msg-click-button-portrait">
          Clique no botão para começar suas compras de forma autônoma!
        </h3>
      </div>
      <Footer />
    </div>
  );
};

export default HomeToten;
